.oxygen-aos-enabled {
  overflow-x: hidden;
}

.ct-inner-content {
  width: 100%;
}

#section-2-16 > .ct-section-inner-wrap {
  max-width: 100%;
}
#section-2-75 > .ct-section-inner-wrap {
  padding-top: 120px;
  padding-right: 30px;
  padding-bottom: 0;
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#section-7-75 > .ct-section-inner-wrap {
  padding-top: 0;
  padding-right: 20px;
  padding-bottom: 0;
  padding-left: 20px;
}

#section-48-75 > .ct-section-inner-wrap {
  padding-top: 50px;
  padding-right: 0;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 991px) {
  #div_block-86-75 {
    width: 100%;
  }
  #div_block-8-75 {
    text-align: left;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }
  #section-2-75 > .ct-section-inner-wrap {
    display: flex;
    flex-direction: column;
  }
  #div_block-44-75 {
    flex-direction: column;
    display: flex;
  }
  #image-96-75 {
    height: 300px;
    width: 100%;
  }
  #div_block-3-75 {
    width: 100%;
    margin-bottom: 40px;
    flex-direction: column-reverse;
    display: flex;
  }
}

@media (max-width: 767px) {
  #div_block-86-75 {
    width: 100%;
  }
  #div_block-8-75 {
    padding-left: 40px;
    padding-right: 40px;
  }
  #div_block-85-75 {
    width: 100%;
  }
  #section-13-75 > .ct-section-inner-wrap {
    padding-right: 30px;
    padding-left: 30px;
  }
  #headline-89-75 {
    font-size: 2rem;
  }
  #section-7-75 > .ct-section-inner-wrap {
    padding-right: 30px;
    padding-left: 30px;
  }
  #div_block-3-75 {
    gap: 20px;
  }
  #section-48-75 > .ct-section-inner-wrap {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 479px) {
  #section-2-75 > .ct-section-inner-wrap {
    padding-top: 100px;
  }
  #div_block-86-75 {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

.ct-section {
  width: 100%;
  background-size: cover;
  background-repeat: repeat;
}
.ct-section > .ct-section-inner-wrap {
  display: flex;
  flex-direction: column;
  align-items: center !important;
}

.ct-section-inner-wrap,
.oxy-header-container {
  max-width: 1120px;
}

.ct-section-inner-wrap {
  padding-top: 75px;
  padding-right: 20px;
  padding-bottom: 75px;
  padding-left: 20px;
}

.navbar-landing .ct-section-inner-wrap {
  padding-top: 15px;
  padding-right: 50px;
  padding-bottom: 15px;
  padding-left: 50px;
}
.navbar-landing:not(.ct-section):not(.oxy-easy-posts),
.navbar-landing.oxy-easy-posts .oxy-posts,
.navbar-landing.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.img-fluid {
  height: 130px;
  width: 370px;
}

@media (max-width: 768px) {
  .img-fluid {
    width: 150px;
    height: 60px;
  }
}

@media (max-width: 767px) {
  /*.ct-section-inner-wrap {
    display: block !important;
  }*/

  div#div_block-44-75 {
    display: block;
  }

  div#div_block-3-75 {
    width: 100%;
  }

  div#div_block-86-75 {
    width: 72%;
  }

  section#section-13-75 {
    margin-top: 10%;
  }

  div#div_block-8-75 {
    display: block;
    text-align: center;
  }

  div#div_block-85-75 {
    width: 85%;
    display: block;
    margin: 0 auto;
  }

  h1#headline-5-75 {
    font-size: 32px;
  }
  .navbar-landing {
  }
  .navbar-landing .ct-section-inner-wrap {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.wrap-icon-social:not(.ct-section):not(.oxy-easy-posts),
.wrap-icon-social.oxy-easy-posts .oxy-posts,
.wrap-icon-social.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.footer .ct-section-inner-wrap {
  padding-top: 30px;
  padding-right: 50px;
  padding-bottom: 30px;
  padding-left: 50px;
}

.footer:not(.ct-section):not(.oxy-easy-posts),
.footer.oxy-easy-posts .oxy-posts,
.footer.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 767px) {
  .footer .ct-section-inner-wrap {
    padding-right: 30px;
    padding-left: 30px;
  }
  .footer:not(.ct-section):not(.oxy-easy-posts),
  .footer.oxy-easy-posts .oxy-posts,
  .footer.ct-section .ct-section-inner-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
  }

  .btn-simulador-seccion1:not(.ct-section):not(.oxy-easy-posts),
  .btn-simulador-seccion1.oxy-easy-posts .oxy-posts,
  .btn-simulador-seccion1.ct-section .ct-section-inner-wrap {
    display: none;
  }
}

.btn-simulador-seccion1:not(.ct-section):not(.oxy-easy-posts),
.btn-simulador-seccion1.oxy-easy-posts .oxy-posts,
.btn-simulador-seccion1.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.block-caracteristicas:not(.ct-section):not(.oxy-easy-posts),
.block-caracteristicas.oxy-easy-posts .oxy-posts,
.block-caracteristicas.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.toggle-faqs:not(.ct-section):not(.oxy-easy-posts),
.toggle-faqs.oxy-easy-posts .oxy-posts,
.toggle-faqs.ct-section .ct-section-inner-wrap {
  display: none;
}

#section-13-75 > .ct-section-inner-wrap {
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
}
#section-13-75 {
  background-color: #fafafa;
}
#section-7-75 > .ct-section-inner-wrap {
  padding-top: 0;
  padding-right: 20px;
  padding-bottom: 0;
  padding-left: 20px;
}
.headline-paso:not(.ct-section):not(.oxy-easy-posts),
.headline-paso.oxy-easy-posts .oxy-posts,
.headline-paso.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#div_block-3-75 {
  width: 80%;
  text-align: left;
  gap: 50px;
}

.ct-div-block {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
}

.ct-new-columns > .ct-div-block {
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}

#text_block-4-75 {
  color: var(--ColorBoton);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ct-div-block,
  .oxy-post-content,
  .ct-text-block,
  .ct-headline,
  .oxy-rich-text,
  .ct-link-text {
    max-width: 100%;
  }
}

.body-small-bold {
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#headline-5-75 {
  font-size: 2.75rem;
}

.ct-text-block {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
.ct-text-block p {
  margin-bottom: 20px;
}
.ct-text-block h1,
.ct-text-block h2,
.ct-text-block h3,
.ct-text-block h4 {
  margin-top: 20px;
}

.ct-text-block .wp-block-image {
  width: 100%;
  margin: 30px 0 30px 0;
}

.ct-image {
  max-width: 100%;
}

#image-96-75 {
  object-fit: cover;
  border-radius: 30px;
  width: 100%;
  height: 300px;
}

div.oxy-dynamic-list div.oxy-product-images.oxy-woo-element {
  align-self: stretch;
}

#headline-89-75 {
  color: #160f29;
  text-align: left;
}

#shortcode-90-75 {
  width: 100%;
}

#wpcf7-f33-o1 {
  font-family: inherit;
  width: 100%;
}
#wpcf7-f33-o1 .wpcf7-form br {
  display: none;
  visibility: hidden;
}
#wpcf7-f33-o1 .wpcf7-form p:nth-child(2) {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}
#wpcf7-f33-o1 .wrap-field {
  position: relative;
  margin-bottom: 20px;
}
#wpcf7-f33-o1 .wrap-field label {
  background-color: #fafafa;
  color: #160f29;
  font-size: 10px;
  text-transform: uppercase;
  padding: 0 10px;
  position: absolute;
  top: -7px;
  margin-left: 15px;
  z-index: 10;
}
#wpcf7-f33-o1 .wrap-field input[type="email"],
#wpcf7-f33-o1 .wrap-field input[type="text"],
#wpcf7-f33-o1 .wrap-field input[type="tel"],
#wpcf7-f33-o1 .wrap-field textarea {
  background-color: transparent;
  width: 100%;
  border: solid 1px #d9d9d9;
  color: #160f29;
  font-size: 16px;
  padding: 15px 15px 10px 15px;
  border-radius: 15px;
  outline: none;
}
#wpcf7-f33-o1 .wrap-field input[type="email"]:focus,
#wpcf7-f33-o1 .wrap-field input[type="text"]:focus,
#wpcf7-f33-o1 .wrap-field input[type="tel"]:focus,
#wpcf7-f33-o1 .wrap-field textarea:focus {
  border: solid 1px #9c36b2;
}
#wpcf7-f33-o1 .wrap-field textarea {
  height: 100px;
}
#wpcf7-f33-o1 input[type="submit"] {
  background-color: #160f29;
  color: #fafafa;
  border: none;
  padding: 15px 20px;
  border-radius: 15px;
  transition: background 0.5s ease;
  cursor: pointer;
}
#wpcf7-f33-o1 input[type="submit"]:hover {
  background-color: #9c36b2;
}
#wpcf7-f152-o2 {
  font-family: inherit;
  width: 100%;
}
#wpcf7-f152-o2 .wpcf7-form br {
  display: none;
  visibility: hidden;
}
#wpcf7-f33-o1 .wpcf7-form p:nth-child(2) {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}
#wpcf7-f152-o2 .wrap-field {
  position: relative;
  margin-bottom: 20px;
}
#wpcf7-f152-o2 .wrap-field label {
  background-color: #fafafa;
  color: #160f29;
  font-size: 10px;
  text-transform: uppercase;
  padding: 0 10px;
  position: absolute;
  top: -7px;
  margin-left: 15px;
  z-index: 10;
}
#wpcf7-f152-o2 .wrap-field input[type="email"],
#wpcf7-f152-o2 .wrap-field input[type="text"],
#wpcf7-f152-o2 .wrap-field input[type="tel"],
#wpcf7-f152-o2 .wrap-field textarea {
  background-color: transparent;
  width: 100%;
  border: solid 1px #d9d9d9;
  color: #160f29;
  font-size: 16px;
  padding: 15px 15px 10px 15px;
  border-radius: 15px;
  outline: none;
}
#wpcf7-f152-o2 .wrap-field input[type="email"]:focus,
#wpcf7-f152-o2 .wrap-field input[type="text"]:focus,
#wpcf7-f152-o2 .wrap-field input[type="tel"]:focus,
#wpcf7-f152-o2 .wrap-field textarea:focus {
  border: solid 1px #9c36b2;
}
#wpcf7-f152-o2 .wrap-field textarea {
  height: 100px;
}
#wpcf7-f152-o2 input[type="submit"] {
  background-color: #160f29;
  color: #fafafa;
  border: none;
  padding: 15px 20px;
  border-radius: 15px;
  transition: background 0.5s ease;
  cursor: pointer;
}
#wpcf7-f152-o2 input[type="submit"]:hover {
  background-color: #9c36b2;
}

#div_block-44-75 {
  align-items: stretch;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  text-align: left;
  display: grid;
}

#text_block-97-75 {
  color: var(--ColorBoton);
  margin-top: 5px;
}

.ct-link-button {
  border-radius: 3px;
}
#div_block-85-75 {
  flex-direction: column;
  display: flex;
  text-align: left;
  gap: 20px;
  width: 45%;
}
.ct-link-text {
  text-decoration: none;
  display: inline-block;
}

.ct-link {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  text-decoration: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ct-link-button {
  display: inline-block;
  text-align: center;
  text-decoration: none;
}
.ct-link-button {
  background-color: #1e73be;
  border: 1px solid #1e73be;
  color: #ffffff;
  padding: 10px 16px;
}

.btn-ppal-neutro:after {
  position: absolute;
  z-index: 1;
  height: 20px;
  width: 20px;
  border-radius: 100px;
  background-color: #574980;
  content: "";
}
.btn-ppal-neutro {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: var(--ColorBoton);
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}
.btn-ppal:after,
.btn-neutro:after,
.btn-ppal-morado:after,
.btn-ppal-neutro:after {
  transition: transform 0.75s ease,
    opacity 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);
  opacity: 0;
}

.btn-ppal:hover:after,
.btn-neutro:hover:after,
.btn-ppal-morado:hover:after,
.btn-ppal-neutro:hover:after {
  transform: scale(30);
  opacity: 1;
}

#text_block-84-75 {
  color: #fafafa;
  z-index: 10;
}

#div_block-8-75 {
  color: white;
  margin-bottom: 50px;
  width: 70%;
  flex-direction: row;
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  background-color: #160f29;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 60px;
}

#div_block-86-75 {
  padding: 40px;
  gap: 30px;
  width: 50%;
  background-color: rgb(250, 250, 250);
  height: auto;
  flex-direction: column;
  display: flex;
  justify-content: center;
  border-radius: 30px;
  box-shadow: rgba(22, 15, 41, 0.5) 10px 10px 32px -12px;
}
