@charset "utf-8";

/* CSS FUENTES  */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "DM Serif Display", serif;
}
h1 {
  font-size: 3em;
  line-height: 1;
  margin-top: 0;
  color: #160f29;
  font-weight: 400 !important;
}
p {
  font-family: "Inter", sans-serif;
  color: #45434d;
}
p.col6 {
  padding: 0 !important;
}
small {
  font-family: "Inter";
  line-height: 20px;
}
.leyeda {
  font-family: "Inter";
  line-height: 20px;
}

small a {
  font-weight: 700;
}
.fa {
  margin-right: 14px;
}
.fa-classic,
.fa-light,
.fa-regular,
.fa-solid,
.fa-thin,
.fal,
.far,
.fas,
.fat {
  font-family: "Font Awesome 6 Pro";
}
.fa-grid-2:before {
  content: "\e196";
}
.fa-memo:before {
  content: "\e1d8";
}
.fa-check-circle:before,
.fa-circle-check:before {
  content: "\f058";
}
.fa-circle-exclamation:before,
.fa-exclamation-circle:before {
  content: "\f06a";
}
.fa-circle-exclamation-blanco:before,
.fa-exclamation-circle:before {
  content: "\f06a";
}

i.fa.fa-circle-check {
  color: #d9d9d9;
}

/* CSS generales   */
body {
  padding: 0;
  margin: 0;
  background: #fafafa;
  height: 100vh;
}
#root {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  height: 100%;
}
header {
  padding: 1em 3%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  width: 100%;
  background: #fafafa;
  z-index: 1;
}
.header_responsive {
  display: none;
}

header,
header * {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.isotipo,
.isotipo_blanco {
  max-width: 50px;
  margin: 0 2em 0 0;
}
.isotipo img, 
.isotipo_blanco img{
  width: 100%;
}
.btn.w100 {
  width: 100% !important;
}
.btn.btn_nuevo {
  background: #000;
}

.margin_auto{
  margin: auto !important;
}

.margin100 {
  margin-top: 100px !important;
  margin-bottom: 3% !important;
}
.col6.sinpadding {
  padding: 0 !important;
}
label {
  font-weight: 600;
}
/***AVISOS ****/
p.aviso_sinDatos {
  background: #ffb92c;
  border-radius: 20px;
  padding: 35px;
}
p.aviso_sinDatos i {
  color: #333 !important;
}
p.avisosinDatos {
  color: #45434d;
}
.bg_morado2 {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.anchoEvenly {
  display: flex;
  justify-content: space-evenly !important;
}
.cont_btn_cotiza{ /*Contenedor de botón para cotizar*/
  display: flex;
  justify-content: center;
}
/****LISTAS***/
ul.redes {
  padding: 0;
  color: #000;
}

ul.redes a {
  padding: 0 10px;
  color: #000;
  text-decoration: none;
}
.cont_Info a {
  text-decoration: none;
}
ul {
  padding: 0;
  margin: 0;
}

ul a {
  text-decoration: none;
  font-family: "Inter";
}
li {
  list-style: none;
}

.logoSesionIniciada {
  border: 1px solid black;
  padding: 5px;
  border-radius: 10px;
  background: #f5f5f5;
}

.iconoUsuarioLoggueado {
  margin-right: 0px;
  margin-left: 15px;
  color: purple;
}

ul.menu a {
  color: #000;
  padding: 10px;
  margin: 0 10px;
  font-size: 13px;
  /*background: #000;*/
}
ul.check li {
  padding: 10px;
  margin-bottom: 1em;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  width: 80%;
}
ul.check a {
  color: #160f29;
}
ul.check a span {
  /*float: right;*/
  font-size: 68%;
  margin-top: 3px;
  white-space: nowrap;
}
/****** BOTONES *******/

.cont_Info.docVivienda a.btn.notas {
  max-width: 150px !important;
  display: block;
  text-align: center;
  padding: 24px !important;
  font-size: 16px;
  font-weight: 600;
}

a.btn.btn_gris {
  background: #f5f5f5;
  border: 0;
  width: 85%;
  cursor: pointer;
  display: block;
  box-sizing: border-box;
}

a.btn.btn_gris:hover {
  background: #9232a6;
  color: #fff;
}
.btn_bco {
  background: #fff;
  border-radius: 10px;
  padding: 15px 20px !important;
  border: 1px solid #9c36b2;
  color: #9c36b2 !important;
}
button {
  cursor: pointer;
}
.btn_nuevo,
.bg_morado2 {
  background: #160f29;
  border-radius: 10px;
  color: #fff !important;
}

.btn_nuevo img {
  margin-right: 10px;
}
/****LINKS ***/
a.link {
  color: #9c36b2;
  font-family: "Inter";
  font-size: 12px;
  margin: 1em 0;
}
a.linkGrande {
  font-weight: bolder;
  font-size: 16px;
}

.link {
  color: #9c36b2;
  font-family: "Inter";
  text-decoration: none;
}
form a.link {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: fit-content;
  text-decoration: none;
}

.cont_btn_cotiza a.btn.btn_personalizado{
  min-width: 100%;
}

/***FORMULARIOS****/
.cont_flex.cont-form {
  display: block;
}

.campo-form {
  width: 30%;
  display: inline-block;
  vertical-align: top;
  margin: 0 10px;
}

.campo-form select {
  width: 32%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background: #e6e6e6;
}

.cont_flex.cont-form form {
  margin-top: 1em;
}
div#delegacion select,
div#colonia select,
.col3.campo-form.colonia select,
.col3.campo-form.estado select {
  width: 100%;
}
a.back {
  color: #9c36b2;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px !important;
  display: block;
}
/*** Contenidos Flex ****/

.cont_flex {
  display: flex;
  column-gap: 1em;
  /*align-items: flex-start;*/
  padding-bottom: 0;
}
.col6.cont_img-index {
  display: flex;
}

.cont_flex .col6 {
  width: 50%;
  padding: 6% 11%;
  box-sizing: border-box;
}

.col6.cont_img {
  /* background: #ddd; */
  padding: 15% 4%;
  width: 50%;
}
.col6.cont_img-index {
  /* background: #ddd; */
  padding: 0;
  width: 50%;
}
.col6.cont_img-index-border {
  padding: 0;
  width: 50%;
}
.bancos-list-container {
  max-height: 200px;
  overflow: auto;
}

.widthCompleto {
  width: 100% !important;
}


.widthCompleto_celular {
  max-height: 600px;
  overflow-y: auto;
}

.widthCompleto_celular::-webkit-scrollbar {
  width: 6px;
}

.widthCompleto::-webkit-scrollbar {
  width: 6px;
}

/*** BANNERS *****/
/* .banner {
    background: linear-gradient(180deg, rgba(13, 13, 13, 0) 0%, rgba(127, 54, 140, 0) 0.01%, #0D0D0D 100%), url(.jpg), #D9D9D9;
    border-radius: 15px;
    padding: 25px;
    color: #fff;
} */

.banner h3 {
  font-weight: 500;
  font-size: 2em;
  width: 30%;
  margin-bottom: 0;
}

.col6.cont_img-index img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.col6.cont_img-index-border img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
}

/*** Formularios ****/
form label {
  display: block;
  font-family: "Inter";
  margin-bottom: 10px;
  color: #9c36b2;
  padding-top: 15px;
}

input {
  padding: 10px;
  width: 100%;
  /* margin-bottom: 23px; */
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  color: #45434d;
  background: #e6e6e6;
}

.span {
  color: rgb(255, 0, 0);
  font-family: "Inter";
  font-size: small;
}

input#txtPrecio {
  border: 0;
  background: 0;
  padding: 0;
  text-align: left;
  font-size: 1.2em;
  margin: 0 10px;
}
::placeholder {
  color: #45434d;
}
.col3.campo-form small {
  margin-top: 0px;
  display: block;
  font-size: 68%;
}
.col3.campo-form button {
  width: 100% !important;
}
button.btn.btn_bco {
  width: 50%;
  max-width: 200px;
}

button.btn.btn_morado {
  width: 50%;
  max-width: 200px;
  border: 0;
}

.botones {
  display: flex;
  column-gap: 2em;
  width: 100%;
  margin-bottom: 25px;
  margin-top: 6%;
}
.botones a.btn.btn_bco {
  /* width: 43%; */
  text-align: center;
  text-decoration: none;
}

/****FOOTER***/
footer {
  background: #160f29;
  padding: 2em 0 0;
}

.wrapper {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
}

footer ul.redes {
  text-align: right;
  float: right;
  display: flex;
  margin: 0;
  align-items: center;
}

footer .wrapper div {
  display: flex;
  align-items: flex-start;
}

div div.menu-politicas{
  display: none;
  margin-top: 2%;
}

div.menu-politicas .copy, div.menu-politicas li{
  color: white;
  font-size: 10.24px;
  margin: 1.2em 0;
}

.menu-footer li {
  list-style: none;
}

.menu-footer li a {
  color: #d9d9d9;
  padding: 0 14px 20px;
  display: block;
  font-size: 16px;
}

.menu-footer {
  width: 90%;
}

footer ul.redes a {
  color: #d9d9d9;
  padding: 0 10px;
}

footer ul.redes_finmax a {
  color: #d9d9d9;
  padding: 0 10px;
}

footer.cont_responsive {
  display: none;
}
/******* Vista Resumen *********/
select#select_login {
  padding: 10px 20px;
  border-radius: 11px;
  background: #f5f5f5;
  border: 0;
}
.menu_lateral_pop {
  display: flex;
  padding: 10%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.widthCompleto_socios {
  width: 100% !important;
}

.menu_lateral {
  background: #f5f5f5;
  border-radius: 20px;
  max-width: 335px;
  width: 30%;
  height: 78vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 3%;
  box-sizing: border-box;
  margin-top: 100px;
}

.menu_lateral ul {
  width: 100%;
  overflow-y: auto;
}
.menu_lateral li a {
  width: 100%;
  display: block;
}

.menu_lateral ul li a.active {
  background: #9c36b2;
  color: #fff;
}
.menu_lateral ul li a {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  box-sizing: border-box;
  /* width: 260px; */
  margin-bottom: 15px;

  /* neutral var 1 */
  background: #fafafa;
  border-radius: 10px;
  color: #160f29;
}

.menu_lateral_pop ul li a {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  box-sizing: border-box;
  margin-bottom: 50px;
  background: #f0f0f0;
  border-radius: 10px;
  color: #160f29;
  font-size: 18px;
}

.menu_lateral .copyright,
.menu_lateral_pop .copyright {
  flex-direction: column;
  display: flex;
  width: 100%;
  color: #160f29;
  margin-top: 18%;
}
.menu_lateral .copyright a,
.menu_lateral_pop .copyright a {
  margin-bottom: 1em;
  color: #160f29;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: fit-content;
  text-decoration: none;
  margin-top: 0;
}
.menu_lateral .copyright p a,
.menu_lateral_pop .copyright p a {
  color: #9c36b2;
  font-size: 100%;
}

.menu_lateral .copyright p,
.menu_lateral_pop .copyright p {
  font-size: 80%;
}

.cont_msgGenerales {
  padding: 0 1em;
  width: 100%;
  margin-top: 120px;
  padding: 0 1em;
  box-sizing: border-box;
}

.cont_Info {
  width: 70%;
  margin-top: 100px;
  padding: 0 1em;
  box-sizing: border-box;
}

h1.titt {
  margin: 0;
  font-size: 2.6em;
  color: #160f29;
}

.Resum .header {
  display: flex;
  align-items: center;
}
.header a.link {
  margin: 0 13px;
}
.header h3 {
  font-family: "Inter";
  font-weight: 600;
  margin: 0;
  font-size: 18px;
}

.cont_loop {
  width: 31%;
  padding: 25px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 25px;
  min-height: 258px;
  margin-bottom: 3em !important;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
}

.cont_loop a {
  display: block;
  text-align: center;
  font-family: "Inter";
}

.cont_loop h3,
.card_contenedor h3 {
  color: var(--ColorBoton);
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 10px;
}

.cont_loop p.descripcion {
  margin: 10px 0 0;
  font-size: 12px;
}

.cont_loop p {
  margin: 0;
}

.cont_score {
  width: 100%;
  padding: 0px 25px 25px 25px;
  box-sizing: border-box;
  background: #ffffff;
  /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1); */
  border-radius: 25px;
  min-height: 258px;
  margin-bottom: 3em;
}

.cardBancos {
  overflow-y: scroll;
  height: 600px;
}

.cont_score p.descripcion {
  font-size: 12px;
  margin-right: 10%;
  /* margin-bottom: 7%; */
  text-align: justify;
  width: 70%;
  padding: 5px 0px;
  margin: initial;
}

.cont_score p.descripcion2 {
  font-size: 11px;
  margin: 2% 5% 2% 0%;
  text-align: justify;
}

.cont_score span.valor {
  font-size: 14px;
  font-weight: bolder;
  color: #000;
}

.cont_score h3 {
  color: #313131;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.cont_score h4 {
  color: #313131;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  margin: 5px;
}

.cont_score div.scoreDatos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #c6c6c6;
}

.cont_score div.scoreDatos2 {
  border-top: 1px solid #c6c6c6;
}

.cont_score div.rangos {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cont_score p.valorRango {
  margin-left: 15%;
  font-size: 14px;
}
.altoAjustado {
  margin-bottom: 0 !important;
  overflow: scroll;
  height: 85vh;
}
/* input range principal */
.cont_score input.barra1 {
  background: transparent;
  padding: 10px 0px 10px 0px;
  overflow: none;
  border-radius: 0px;
  box-sizing: content-box;
  border: none;
  -webkit-appearance: none;
  margin: 0;
}

.cont_score input.barra2 {
  width: 80%;
}

.cont_score input.barra3 {
  width: 100%;
}

/* barra de colores */
.cont_score input.barra1::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: linear-gradient(
    to right,
    #ed4a2b 17%,
    white 17%,
    white 20%,
    #fdba2e 20%,
    #fdba2e 37%,
    white 37%,
    white 40%,
    #ef892b 40%,
    #ef892b 57%,
    white 57%,
    white 60%,
    #27d8bc 60%,
    #27d8bc 77%,
    white 77%,
    white 80%,
    #a3d828 80%,
    #a3d828 100%
  );
}

@-moz-document url-prefix() {
  .cont_score input.barra1::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: linear-gradient(
      to right,
      #ed4a2b 17%,
      white 17%,
      white 20%,
      #fdba2e 20%,
      #fdba2e 37%,
      white 37%,
      white 40%,
      #ef892b 40%,
      #ef892b 57%,
      white 57%,
      white 60%,
      #27d8bc 60%,
      #27d8bc 77%,
      white 77%,
      white 80%,
      #a3d828 80%,
      #a3d828 100%
    );
  }
}

.cont_score input.barra2::-webkit-slider-runnable-track {
  width: 80%;
  height: 8px;
  cursor: pointer;
  background: linear-gradient(
    to right,
    #9eda21 30%,
    white 30%,
    white 35%,
    #fdba30 35%,
    #fdba30 65%,
    white 65%,
    white 70%,
    #ed4b25 70%,
    #ed4b25 100%
  );
}

@-moz-document url-prefix() {
  .cont_score input.barra2::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: linear-gradient(
      to right,
      #9eda21 30%,
      white 30%,
      white 35%,
      #fdba30 35%,
      #fdba30 65%,
      white 65%,
      white 70%,
      #ed4b25 70%,
      #ed4b25 100%
    );
  }
}

.cont_score input.barra3::-webkit-slider-runnable-track {
  width: 60% !important;
  height: 8px;
  cursor: pointer;
  background: linear-gradient(
    to right,
    #9eda21 48%,
    white 48%,
    white 52%,
    #ed4b25 52%,
    #ed4b25 100%
  );
}

@-moz-document url-prefix() {
  .cont_score input.barra3::-moz-range-track {
    width: 100% !important;
    height: 8px;
    cursor: pointer;
    background: linear-gradient(
      to right,
      #9eda21 47%,
      white 47%,
      white 53%,
      #ed4b25 53%,
      #ed4b25 100%
    );
  }
}

/* Control del input */
.cont_score input.barra1::-webkit-slider-thumb {
  box-shadow: 1px 1px 2px transparent;
  border: 2px solid #a8a8a8;
  height: 20px;
  width: 20px;
  background: #858585;
  -webkit-appearance: none;
  margin-top: -5px;
}

@-moz-document url-prefix() {
  .cont_score input.barra1::-moz-range-thumb {
    box-shadow: 1px 1px 2px transparent;
    border: 2px solid #a8a8a8;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #858585;
    margin-top: -5px;
  }

  .cont_score input.barra1::-moz-range-progress {
    background: transparent;
  }
}

.plazo {
  display: flex;
  column-gap: 1em;
  margin: 1.3em 0;
  flex-direction: column;
}

.plazo span {
  display: block;
}

.plazo p {
  width: 50%;
  margin: 10px 0;
  font-size: 12px;
}

.plazo p span {
  font-size: 16px;
}

.Resum {
  margin: 3em 0;
}
.Resum.cont-Cotizaciones .bg_morado2 a.btn_morado {
  width: auto;
}
p.tasa {
  margin: 14px 0;
  padding: 10px;
  background: #d9d9d9;
  border-radius: 10px;
  font-size: 80%;
  display: flex;
  justify-content: space-between;
}

p.tasa span.monto {
  font-size: 118%;
  font-weight: 700;
}

.Resum h3 {
  font-family: "Inter";
}

.bg_gris_avaluo {
  padding: 32px;
  background: #e6e6e6;
  border-radius: 30px;
  text-align: center;
}

.bg_gris2 {
  height: 100%;
  box-sizing: border-box;
  padding: 32px;
  background: #45434d !important;
  border-radius: 30px;
  color: #fff;
  box-shadow: 10px 10px 30px 0px #160f2940 !important;
}

.bg_gris {
  padding: 32px;
  background: #e6e6e6;
  border-radius: 30px;
}
.bg_gris ul li {
  display: inline-flex;
  width: 47%;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  padding: 10px;
  box-sizing: border-box;
  margin: 0 10px;
  font-family: "Inter";
  font-size: 80%;
}
.bg_gris2 ul li {
  display: inline-flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  font-family: "Inter";
  font-size: 80%;
}

.bg_gris ul li span {
  font-weight: 800;
  font-size: 1.2em;
}
.dosColumns.cont_flex .col6 {
  padding: 0;
  background: no-repeat;
  /* height: 100%; */
}
.cont_img.col6 img {
  width: 100%;
  /* border-radius: 60px; */
}
.dosColumns.cont_flex .btn_morado {
  display: block;
  width: 75%;
  text-align: center;
}
/****APROBADO*****/
.col6.cont_img .bg_morado2 {
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  margin-bottom: 1em;
  padding: 50px;
  box-shadow: 10px 10px 30px 0px #160f2940;
}

.col6.cont_img .bg_morado2 * {
  margin: 0;
}

p.aprobado {
  color: #9dd925;
  font-size: 80%;
}

.col6.cont_img .bg_morado2 a {
  color: #fff;
  font-family: "Inter";
  font-size: 77%;
}

.col6.cont_img .bg_morado2 h3 {
  font-weight: 400;
  margin: 10px 0;
  font-size: 1.8em;
}
.col6.cont_img .bg_morado2 * i.fa {
  display: block;
  font-size: 2em;
  color: #9dd925;
}
.col6.sinpadding form {
  width: 82%;
}
i.fa.fa-circle-exclamation {
  color: #160f29;
  font-size: 1.4em;
}
i.fa.fa-circle-exclamation-blanco {
  color: white;
  font-size: 1.4em;
}

.bg_gris2 h4 {
  font-weight: 400;
  margin: 0;
}

.bg_gris2 ul li .fa {
  color: #ffb92c;
}

.bg_gris2 a {
  color: #fff;
  font-size: 12px;
  font-family: "Inter";
}

.col6.sinpadding p {
  /* margin: 0; */
  width: 80%;
}
.cont_flex.wrapper.margin100 {
  align-items: stretch !important;
}

/***DOCUMENTO ENVIADOS***/
.bg_verde {
  padding: 30px !important;
  background-color: #8bbf21 !important;
  border-radius: 30px;
  /* height: 100%; */
  color: #160f29;
}

.dosColumns.cont_flex {
  align-items: stretch !important;
}

.col6.bg_verde a {
  background: #160f29;
  color: #fff;
  margin: 1em 0;
  display: inline-block;
  text-decoration: none;
}
/****simulador*****/

.col6.simulador {
  padding: 0;
  font-family: "inter";
  width: 60%;
}

.col6.simulador label {
  color: #9c36b2;
  display: block;
}

.precio_cotizar p {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.enganche_cotizar p{
  display: flex;
  justify-content: space-between;
  width: auto;
}

.simulador i {
  float: right;
  color: #45434d !important;
}

.precio_barra,
.precio_plazo {
  margin: 1em 0;
}
.precio_barra .description {
  display: flex;
  justify-content: space-between;
  font-size: 80%;
  margin: 10px 0;
}

.barra {
  background: #f5f5f5;
  padding: 10px;
  height: 30px;
  border-radius: 12px;
  margin: 10px 0;
  display: flex;
}

span.select {
  height: 5px;
  background: linear-gradient(45deg, #9c36b2 50%, #d9d9d9 50%);
  width: 100%;
  display: block;
  margin: auto;
  border-radius: 10px;
}

span.select:before {
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  background: #9c36b2;
  margin: -8px auto;
  border-radius: 100%;
}
.simulador .bg_morado2,
.resultados.bg_morado2 {
  flex-direction: column;
  text-align: left;
  align-content: flex-start;
  justify-content: space-evenly;
  padding: 30px;
}

.cont_flex.strech {
  align-items: stretch;
}
.resultados.bg_morado2 {
  width: 39%;
}

.cont_flex.strech {
  align-items: stretch;
}

.simulador .bg_morado2 h3,
.resultados.bg_morado2 h3 {
  margin: 0;
  text-align: left;
  width: 100%;
  font-weight: 400;
}

.btn_morado .bg_morado2 {
  width: 100%;
  display: block;
}

.bg_morado2 a.btn_morado {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  margin-top: 1em;
}
.col6.resultados.bg_morado2 i {
  color: #d9d9d9;
  margin: 0 4px;
  font-size: 89%;
}
.resultados.bg_morado2 h3 {
  font-size: 1.6em;
}

.resultados.bg_morado2 ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 80%;
  font-family: "Inter";
  padding: 10px 0;
  border-bottom: 1px solid #333;
}

.resultados.bg_morado2 ul li span {
  text-align: right;
  width: 60%;
}

.resultados.bg_morado2 ul {
  margin-bottom: 5em;
  width: 100%;
}

.resultados.bg_morado2 small {
  font-size: 65%;
  text-align: left !important;
  display: block;
  width: 100%;
  line-height: 1.1;
}

.cont_flex.strech {
  align-items: stretch;
}

.simulador .bg_morado2 h3,
.resultados.bg_morado2 h3 {
  margin: 0;
  text-align: left;
  width: 100%;
  font-weight: 400;
}

.btn_morado .bg_morado2 {
  width: 100%;
  display: block;
}

.bg_morado2 a.btn_morado {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  margin-top: 1em;
}

.resultados.bg_morado2 h3 {
  font-size: 1.6em;
}

.resultados.bg_morado2 ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 80%;
  font-family: "Inter";
  padding: 10px 0;
  border-bottom: 1px solid #333;
}

.precio_plazo button {
  width: 22%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #1e1e1e;
}

.precio_plazo label {
  margin-bottom: 11px;
}

.cont_flex.strech {
  align-items: stretch;
}

.simulador .bg_morado2 h3,
.resultados.bg_morado2 h3 {
  margin: 0;
  text-align: left;
  width: 100%;
  font-weight: 400;
}

.btn_morado .bg_morado2 {
  width: 100%;
  display: block;
}

.bg_morado2 a.btn_morado {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  margin-top: 1em;
}

.resultados.bg_morado2 h3 {
  font-size: 1.6em;
}

.resultados.bg_morado2 ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 80%;
  font-family: "Inter";
  padding: 10px 0;
  border-bottom: 1px solid #d9d9d9;
  color: #d9d9d9;
}

.cont_calculadora_btns{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
  justify-items: center;
}

.cont_calculadora_btns button{
  width: 90% !important;
  padding: 10px 5px !important;
}

.precio_plazo button {
  width: 22%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #1e1e1e;
  margin: 6px;
  width: 21%;
}

.precio_plazo label {
  margin-bottom: 11px;
}

/************* Responsivo ***********/
@media screen and (max-width: 1366px) {
  .cont_flex .col6{
    padding-top: 7.5%;
  }
}

@media screen and (max-width: 1040px){
  .cont_flex .col6{
    padding-top: 9.5%;
  }

  .modal_pop{
    width: 40% !important;
  }
}

@media screen and (max-width: 805px){
  .cont_calculadora_btns{
    grid-template-columns: repeat(2, minmax(75px, 1fr));
  }

  .cont_flex .col6{
    padding-top: 12.5%;
  }
}

@media screen and (max-width: 783px){
  .cont_informacion_btns{
    grid-template-columns: repeat(1, minmax(75px, 1fr)) !important;
  }

  .cont_informacion_btns a.btn{
    margin-bottom: 1em;
    min-width: 250px;
  }
}

@media screen and (max-height: 700px) {
  #showBuroResults {
    height: 90% !important;
    overflow-y: scroll !important;
  }
}

@media (max-width: 767px) {
  .menu_lateral {
    display: none !important;
    background: #f5f5f5;
    border-radius: 20px;
    max-width: 335px;
    width: 30%;
    height: 80vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    padding: 3%;
    box-sizing: border-box;
    margin-top: 100px;
  }

  /*.cont_flex {
    flex-direction: column !important;
  }*/

  .cont_loop.descarga {
    /* Permite que los elementos se envuelvan en dispositivos m?viles */
    flex-wrap: wrap !important;
  }

  #sinInformacion,
  #informacion {
    /* Cambia el orden de los elementos en dispositivos m?viles */
    order: 1 !important;
  }
}

@media screen and (max-width: 768px) {
  .tablaFijosCeldasDatos {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid gray;
    padding: 5px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    align-items: flex-start;
  }
}

@media screen and (max-height: 750px){
  .menu_lateral_pop {
    padding: 1em 10%;
  }

  .menu_lateral_pop ul li a{
    margin-bottom: 6vh !important;
  }

  .menu_lateral_pop .copyright{
    margin-top: 6vh !important;
  }

  .menu_lateral_pop .botonesModal{
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .etiquetaTablaBancos {
    font-size: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .cont_Info.docVivienda .cont_loop a {
    padding: 20px 20px !important;
    width: 80px !important;
    height: 80px !important;
  }
}

/* Flecha de Documentos Vivienda */
@media screen and (max-width: 768px) {
  .logo_mobil {
    width: 50px !important;
    height: 30px !important;
  }
}

@media screen and (max-height: 700px) {
  #showBuroResults {
    height: 90% !important;
    overflow-y: scroll !important;
  }
}

@media screen and (max-width: 600px) {
  .cont_calculadora_btns{
    grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
  }

  .cont_flex .col6{
    padding-top: 6%;
  }
  /****ObservarRegistro****/
  .cont_flex.wrapper.margin100 .col3{
    padding: 0 1em;
  }
  .numeros .active span.descripcion-paso {
    position: absolute;
    bottom: -22px;
    color: #45434d;
    width: max-content;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .numeros .active {
    height: 20px;
    width: 20px !important;
  }

  .numeros {
    position: relative;
  }
  /****socios***/
  .dosCuttonFormularios {
    width: 100% !important;
    padding: 0 !important;
    margin-top: 20px;
  }
  .dosCuttonFormularios a,
  .dosCuttonFormularios button {
    height: auto;
    margin: 0;
  }
  .ag-theme-material {
    overflow: scroll;
    width: 100% !important;
  }
  .logo_client {
    width: 100%;
  }
  button.btn.btn_morado {
    max-width: 100%;
  }
  /****calculadora***/
  .col6.simulador {
    margin: 1em 0;
  }
  .numeros p {
    float: left;
    margin: 3px !important;
  }
  .numeros:after {
    content: "";
    display: block;
    clear: both;
  }
  .dosColumns.cont_flex .col6.cont_img {
    display: none;
  }
  .resumen,
  .pagoEvaluo {
    flex-direction: column !important;
  }
  .cont_flex.con_flex_wrap.resumen h3 {
    text-align: center;
  }

  .cont_logo {
    display: block !important;
  }
  .cont_logo img {
    width: 50% !important;
    margin: 0 auto !important;
  }
  .cont_logo h1 {
    font-size: 20px;
  }
  .cont_flex.con_flex_wrap.resumen h3,
  .cont_flex.con_flex_wrap.resumen .bg_gris {
    width: 100% !important;
    box-sizing: border-box;
    margin: 1em 0;
  }

  a.btn {
    width: 100%;
    display: block;
    text-align: center;
  }

  .estiloBotonVivienda
  {
    width: 100% !important;
    display: block !important;
    text-align: center !important;
  }

  .tablaFijosCeldasDatos {
    flex-flow: wrap;
  }
  .numeros {
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  .etiquetaTablaBancos {
    width: 40% !important;
  }

  .botonTablaBancos {
    width: 100% !important;
  }
  .number-box span.number {
    font-size: 80%;
  }
  a.btn.btn_big {
    width: 100%;
    margin: 1em 0;
    box-sizing: border-box;
  }
  .cont_Info > p {
    width: 100% !important;
  }
  .cont_loop.sinFondo {
    width: 100%;
    display: block !important;
  }
  .cont_responsive img {
    width: 100%;
    margin: 1em 0;
  }

  .banner h3 {
    width: auto;
  }

  .col7.cont_img img {
    width: 100%;
  }

  .btn,
  button.btn.btn_bco,
  button.btn.btn_morado,
  a.btn {
    box-sizing: border-box;
    margin: 1em 0;
    /*width: 100% !important;*/
    display: block !important;
    max-width: 100% !important;
  }
  input.btn.btn_morado[type="file"] {
    margin: 0;
  }
  .cont_flex.cont-form.formInfoGeneral {
    flex-direction: column;
  }

  .cont_flex.cont-form.formInfoGeneral,
  div#todasCotizaciones {
    flex-direction: column;
  }

  .cont_msgGenerales {
    margin-top: 40px;
  }

  .cont_score p.descripcion {
    width: 65%;
  }

  .margin100 {
    margin-top: 30px !important;
  }
  /***RESUMEN****/
  .Resum .header {
    justify-content: space-between;
    margin-bottom: 1em;
  }

  .Resum .cont_loop {
    display: none;
  }

  .Resum .cont_loop:nth-child(0) {
    display: block;
  }
  .col3.campo-form {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
  }
  /***FIN RESUMEN****/
  ul.menu_responsive {
    display: none;
  }
  footer {
    padding: 2em 0;
  }
  footer.cont_responsive {
    display: block;
    padding: 1em;
  }

  footer.cont_responsive .copyright,
  footer.cont_responsive .copyright a,
  footer.cont_responsive .copyright p {
    display: block;
    color: #fff;
    font-size: 80%;
  }

  button.lines-button.x {
    /* position: absolute; */
    top: 11px;
    padding: 0;
    right: 11px;
    width: 24px;
    height: 32px;
    border: 0;
    background: no-repeat;
  }

  span.lines {
    width: 100%;
    height: 2px;
    background: #9c36b2;
    display: block;
    position: relative;
  }

  span.lines:before,
  span.lines:after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: #9c36b2;
    margin: 8px 0;
    position: absolute;
  }

  span.lines:before {
    top: 0px;
  }

  span.lines:after {
    top: -17px;
  }
  .header_responsive {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 8px auto 0;
  }
  ul.menu_responsive {
    display: none;
  }
  ul.menu a {
    border: 0;
    background: no-repeat;
  }
  .cont_flex,
  .cont_loop {
    flex-direction: column-reverse;
    width: 100% !important;
  }

  .cont_flex .col6 {
    width: 100% !important;
    padding: 1em !important;
  }

  header ul.redes {
    display: none;
  }

  header .isotipo {
    display: none;
  }

  ul.menu li:last-child {
    display: block;
  }

  ul.menu li {
    display: none;
  }

  header div {
    display: none;
  }

  ul.menu a {
    border: 0;
    background: no-repeat;
  }

  footer .wrapper div {
    flex-direction: column;
  }

  .menu-footer {
    display: none !important;
  }

  div div.menu-politicas{
    display: block;
  }

  .botones {
    flex-direction: column;
  }

  .botones .btn {
    width: 100% !important;
    box-sizing: border-box;
    margin-bottom: 1em;
  }

  footer ul.redes {
    display: none;
  }

  form a.link {
    width: max-content;
    text-align: center;
    margin: 0 auto;
    display: block;
  }

  .col6.cont_img {
    padding: 0;
  }
  header {
    display: none;
  }

  .cont_loop {
    width: 100%;
    margin-bottom: 2em !important;
  }

  .Resum,
  .cont_Info {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .menu_lateral {
    display: none;
  }
  .header_responsive img {
    margin: 0;
    width: 100px;
  }

  .modal_pop,
  .modal_pop_buro {
    width: 60% !important;
  }

  #mostrarFijosResponsivo,
  #mostrarBancosNoSeleccionadosTablaResponsivo {
    display: inline !important;
  }

  #mostrarFijos,
  #tituloTablaBancosFijos,
  #btnAplicarBancosPequeno,
  #mostrarBancosNoSeleccionadosTabla {
    display: none !important;
  }

  #btnAplicarBancosGrande {
    display: inline !important;
  }

  .responsivoColumn {
    flex-direction: column;
  }

  .botonAplicarGrande {
    width: 80%;
    background: #9c36b2;
    border-radius: 10px;
    padding: 15px 20px !important;
    font-size: 13px;
    color: #fff !important;
    border: 1px solid #160f29;
    font-family: "Inter";
    display: inline-block;
    text-decoration: none;
  }

  .anchoEvenly {
    flex-direction: column;
  }

  .textoCentrado {
    margin: 5px;
  }

  #mainDivCalculadora {
    flex-direction: column;
  }

  #confirmarResultadosCalculadoraMovil {
    display: block !important;
    margin-top: 10px;
    text-align: center;
  }

  #confirmarResultadosCalculadoraWeb {
    display: none;
  }

  /*InformacionGeneral Formulario*/
  .button_YN {
    margin-bottom: 1em;
  }

  /*Documentación Inicial*/
  div.container_archivo div.input_archivo input{
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media screen and (max-width: 500px){
  .pop_sms{
    width: 75% !important;
  }
}

@media screen and (max-width: 400px){
  .ancho70 {
    width: auto !important;
  }

  .modal_pop{
    width: 70% !important;
  }
}

@media screen and (max-width: 370px){
  .cont_calculadora_btns{
    grid-template-columns: repeat(2, minmax(75px, 1fr));
  }

  .modal_pop{
    width: 78% !important;
  }
}

.width80 {
  width: 80% !important;
}
#btnAplicarBancosGrande,
#confirmarResultadosCalculadoraMovil {
  display: none;
}

/* Estilo campos y calculadora */
.txtRight {
  text-align: right;
}

.btn_plazo {
  background-color: #d9d9d9;
  font-weight: normal;
}
.btn_plazo_selected {
  background-color: #9c36b2;
  font-weight: bold;
}
input#txtPrecio {
  border: 0;
  background: 0;
  padding: 0;
  text-align: left;
  font-size: 1.2em;
  margin: 0 10px;
}

input#txtEnganche{
  border: 0;
  background: 0;
  padding: 0;
  text-align: left;
  margin: 0 5px;
  width: 12ch;
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"] {
    overflow: hidden;
    width: 100%;
    -webkit-appearance: none;
    background-color: #f0f0f0;
    margin: 1em 0 0;
    /* display: block; */
    height: 9px !important;
    padding: 0;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    height: 16px;
    -webkit-appearance: none;
    color: #9c36b2;
    margin-top: 0px;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 20px;
    border-radius: 100%;
    background: #160f29;
    cursor: pointer;
    box-shadow: -300px 0px 0 300px #9c36b2;
  }
}
/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: #9c36b2;
}
input[type="range"]::-moz-range-track {
  background-color: #f0f0f0;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #9c36b2;
}
input[type="range"]::-ms-fill-upper {
  background-color: #f0f0f0;
}
.precio_plazo button:hover,
.precio_plazo button.btn_plazo_selected {
  color: #fff;
  background: #9c36b2;
}

.formInfoGeneral {
  display: flex !important;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 10px;
}

.marginEspacio {
  margin: 10px;
}

.marginEspacioArribaAbajo {
  margin: 10px 0px;
}
/* Estilos de modales */
.modal_pop {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 20%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  z-index: 1000;
  text-align: center;
  border-radius: 40px;
}

.modal_pop .modal_pop.doble_pop{
  width: 100% !important;
}

.modal_pop_resultado {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  z-index: 1000;
  text-align: center;
  border-radius: 40px;
}

.modal_pop_buro {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 20%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 10px;
  z-index: 1000;
  text-align: center;
  border-radius: 40px;
}

.pop_sms{
  min-width: 250px;
}

.anchoGrandePop,
.pop_sms {
  width: 25%;
}

.anchoGrandePop30 {
  width: 30%;
}

.modal_pop_menuLateral {
  position: fixed;
  top: 0%;
  left: 0%;
  bottom: 0%;
  right: 0%;
  background-color: #fff;
  z-index: 1000;
  text-align: center;
  overflow-y: scroll;
}

.anchoGrandePopMenuLateral {
  width: 100%;
}

.modal_back {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.closeModal {
  text-align: right;
  margin-bottom: 15px;
  font-size: 25px;
  cursor: pointer;
}

.closeModal_buro {
  text-align: right;
  /* margin-bottom: 15px; */
  font-size: 17px;
  cursor: pointer;
  color: black;
}

.closeModal_menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  text-align: right;
  margin-bottom: 15px;
  font-size: 17px;
}
.divCodigoSms {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.divCodigoSms input {
  margin: 10px;
  height: 60px;
  width: 60px;
  background: transparent;
  text-align: center;
}

.reenviarSms {
  font-size: 14px;
  padding: 0px 0px 20px 0px;
}

.botonesModal {
  display: flex;
  column-gap: 2em;
  width: 100%;
  margin-top: 20px;
  justify-content: center;
}

.aceptarTerminos {
  display: flex;
  text-align: center;
}

.aceptarTerminos input {
  width: 10%;
  height: 15px;
  padding: 5px;
}

.aceptarTerminos label {
  text-align: justify;
  width: 80%;
  padding: 0;
}

.cont_text a {
  font-size: 16px;
}

.con_flex_wrap {
  flex-wrap: wrap;
}

/***Cambios 03/01/23***/
.cont_flex.flex_center {
  justify-content: center;
}

.tablaFijosCeldasTitulo {
  border-bottom: 2px solid gray;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
}

.tablaFijosCeldas {
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
}
.tablaFijosCeldasDatos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  padding: 5px;
}

.tablaFijosCeldasDatosResponsivo {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  margin: 10px 0px;
  padding: 15px;
}

.tituloTablaBancosSeleccionar {
  width: 40%;
  padding: 5px 0px;
}

.negritas {
  font-weight: 700;
}

.btnOfertasTabla {
  padding: 10px;
  width: 80%;
  background: #9c36b2;
  border-radius: 10px;
  font-size: 13px;
  color: #fff !important;
  border: 0;
  text-decoration: none;
}

.masInfoBancos {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;

  /* or 142% */
  display: flex;
  align-items: center;
  color: black;
}

.centradoParrafo {
  text-align: left;
}

.imgBancos {
  /* Inside auto layout */
  flex: none;
  /*order: 1;*/
  flex-grow: 0;
}
img.imgBancos {
  max-width: 190px;
  margin: 0 auto;
}
.imagenBancoCard {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  height: 60px;
  margin-bottom: 20px;
}

.negativo {
  background-color: black;
  color: white;
}

.bancoSeleccionadoTarjeta {
  border: 3px solid purple;
}

.cont_loop button {
  width: 100% !important;
}

#guardarBancosBotonSeleccion {
  text-align: center;
  margin-top: 15px;
}

.botonSeleccionadoBancosDeshabilitado {
  background-color: #e6e6e6;
  color: #000;
  font-weight: 600;
}

.msgBancoAplicado {
  text-align: center;
  color: red;
}

.width100 {
  width: 100% !important;
}

.pequeno_boton {
  width: 30% !important;
}
.padding_pequeno {
  padding: 2%;
}

.btn_validandoBanco {
  border: 1px solid black;
  align-items: center;
  padding: 10px 15px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  color: black;
  background-color: #e6e6e6;
}

.datosCardResumen {
  width: 50%;
}

.plazoCardResumen {
  flex-direction: row;
  justify-content: space-around;
}

.button_YN {
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  border-radius: 10px;
  padding: 15px 20px !important;
  font-size: 13px;
  border: 1px solid #160f29;
  font-family: "Inter";
  display: inline-block;
  text-decoration: none;
}

.button:hover {
  background-color: #9c36b2; /* Green */
  color: white;
}

.ocultarDiv {
  display: none;
}

.etiquetaTablaBancos {
  width: 14%;
  font-size: 15px;
  text-align: center;
  padding: 10px;
  text-overflow: clip;
  overflow: hidden;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
  font-family: inter;
}

.bloque{
  display: block !important;
}

/*JPB Para que no se salga la cantidad de la celda*/
.cantidadMXNTablaBancos {
  width: 14%;
  font-size: 13px;
  text-align: center;
  padding: 10px;
  text-overflow: clip;
  overflow: hidden;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
  font-family: inter;
}

.botonTablaBancos {
  width: 15%;
  text-align: center;
  height: 100px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.botonTablaBancosResponsivo {
  width: 50%;
  text-align: center;
  margin-top: 10px;
}

#mostrarFijosResponsivo,
#mostrarBancosNoSeleccionadosTablaResponsivo {
  display: none;
}

.dosCuttonFormularios {
  padding-top: 20px;
  width: 64%;
  padding-right: 30px;
  display: flex;
  column-gap: 1em;
  margin-bottom: 25px;
}

.elementoFlex {
  display: flex;
  align-items: center;
}

.centrartarjeta {
  display: flex;
  text-align: center;
  justify-content: center;
}

.ancho70 {
  width: 70% !important;
}

.textoCentrado {
  text-align: center;
}

.iconoOcultarPassword {
  height: 1.5rem;
  width: 1.5rem;
  padding: 4px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
}
.relativePosition {
  position: relative;
}

.morado {
  color: purple;
}

.negritas {
  font-weight: bold;
}

.subMenu {
  cursor: pointer;
  width: 80%;
  margin-left: 20%;
}

ul.check li.linkInfoGeneral,
ul.check li.linkInfoGeneralCoAcreditado{
  min-width: fit-content;
  width: auto;
}

ul.check li.informacionGeneralCompletado {
  border: 3px solid #9dd925;
}

.scrollable-container {
  max-height: 200px;
  overflow-y: auto;
}

div#MultiArchivoScroller.scrollable-container {
  max-height: 135px;
  overflow-y: auto; 
  overflow-x: hidden;
}

li.active i.fa.fa-circle-check {
  color: #8bc34a;
}

.documentoSubido {
  border: 3px solid #9dd925 !important;
}

.opcionesRadios {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-check {
  display: flex;
  align-items: center;
}

.centradoRadiosButon {
  margin-left: 30%;
}

.justificado {
  text-align: justify;
}

/****Cambios 19/07/23 pasos*****/
.numeros {
  display: flex;
}
.pasos {
  margin-bottom: 2em;
}
.numeros p {
  margin: 0 10px 12px;
  padding: 10px;
  background: #e6e6e6;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  text-align: center;
}

.numeros .active {
  width: max-content;
  border-radius: 20px;
  background: #9c36b2;
  color: #fff;
}

.col3.campo-form.estado.marginEspacio label {
  padding: 0;
}

/*Nuevos cambios 18/08/2023*/
.tabla_valores .cont_loop {
  width: 49%;
  background: none;
  box-shadow: none;
  margin-bottom: 0;
}

.tabla_valores {
  background: #f5f5f5;
  border-radius: 40px;
}

.tabla_valores .etiquetaTablaBancos {
  width: 50%;
  text-align: left;
  font-family: "inter";
  padding: 5px 0;
}

.tabla_valores .etiquetaTablaBancos.cantidad {
  font-weight: 800;
  text-align: right;
}

.cont_loop.descarga {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100px !important;
  background: #f5f5f5;
  box-shadow: none;
}

.cont_loop.descarga h3 {
  margin: 0 33px;
  width: 53%;
  color: #221b34;
}

.certificados .cont_loop {
  width: 23%;
  box-shadow: none;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.certificados .cont_loop h3 {
  color: #000;
  text-align: center;
  font-size: 1.3em;
  font-weight: 800;
}

.certificados .cont_loop p {
  text-align: center;
  font-size: 80%;
}

.cont_flex.con_flex_wrap.certificados {
  margin-top: 2em;
}

.cont_Info.docVivienda p {
  width: 60%;
}

.cont_Info.docVivienda .cont_loop {
  background: none;
  box-shadow: none;
  border: 1px solid #d9d9d9;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 200px;
}

.cont_Info.docVivienda .cont_loop h3 {
  text-align: center;
  color: #000;
  font-size: 1.2em;
  font-family: "inter";
  line-height: 1;
  font-weight: 600;
}

.cont_Info.docVivienda .cont_loop a {
  margin: 0 auto;
  width: 40px;
  padding: 0 !important;
  border-radius: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
}

.cont_Info > p {
  width: 70%;
}
.cont_loop.sinFondo {
  border: 1px solid #d9d9d9;
  box-shadow: none;
  background: none;
  min-height: auto;
  width: 43%;
  position: relative;
}

.cont_loop.sinFondo input {
  position: initial !important;

  height: 49px !important;
}

.cont_loop.sinFondo h3 {
  color: #160f29;
  font-family: "inter";
  font-size: 20px;
  font-weight: 600;
}

input.btn.btn_morado[type="file"] {
  background: no-repeat;
  appearance: none !important;
  border: 1px dashed #333;
}

.cajasChidas {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 1em;
}
div#calculoDias .cont_loop {
  width: 100%;
  background: #f5f5f5;
  box-shadow: none;
  display: flex;
  min-height: 160px;
  align-items: center;
}

div#calculoDias .cont_loop h3 {
  color: #000;
}

div#calculoDias .cont_loop p {
  width: 50%;
}

.number-side {
  display: flex;
  text-align: center;
}

.number-box {
  background: #fff;
  padding: 10px;
  margin: 10px;
  font-family: "Inter";
}

.number-box span.number {
  color: #9c36b2;
}
.number-box span.number b {
  font-size: 2em;
}

.cont_flex.con_flex_wrap.pagoEvaluo .bg_gris h3 {
  color: #000;
  font-family: "inter";
  font-size: 1.3em;
}

.cont_flex.con_flex_wrap.pagoEvaluo .bg_gris {
  min-height: 185px;
  width: 50%;
}

.cont_flex.con_flex_wrap.resumen h3 {
  width: 17%;
  font-family: inter;
  font-weight: 600;
  font-size: 19px;
}

.cont_flex.con_flex_wrap.resumen .bg_gris p:nth-child(1) {
  font-size: 90%;
  margin: 0;
}

.cont_flex.con_flex_wrap.resumen .bg_gris p:nth-child(2) {
  margin-top: 0;
}

.cont_flex.con_flex_wrap.resumen .bg_gris {
  width: 33%;
  /*padding: 10px 30px 0;*/
  border-radius: 20px;
}

.descarga .cont_loop {
  margin-bottom: 0;
  min-height: 179px;
  box-shadow: none;
}

.descarga .cont_loop a {
  margin-top: 1em;
}

.cont_Info.docVivienda {
  margin-top: 1em;
  width: 100%;
}

.cont_Info.docVivienda .cont_loop {
  margin-bottom: 0;
}
div#calculoDias .cont_loop h3.titt {
  text-align: center !important;
  font-family: "Inter", sans-serif;
  font-size: 1em;
  margin: 0;
  color: #9c36b2;
}
.tablaFijosCeldasDatos p {
  text-align: left;
}

.etiquetaTablaBancos p:first-child {
  font-size: 79%;
}

h3.titt.notas {
  font-family: inter;
  color: #45434d;
  font-size: 16px;
}
.btn_big {
  margin: 0 10px;
  text-align: center;
  min-width: 200px !important;
  display: inline-block;
}

.inputGrande {
  width: 62%;
}

/*Estilos para las tablas ag grid*/
/* Cambiar el color de fondo del encabezado de columna a azul */
.ag-header-cell {
  background-color: #ffffff;
  color: #45434d;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
/* Aplicar estilo a todas las celdas en Ag-Grid */
.ag-cell {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

/***CAMBIOS 21-09-23***/
.bg_gris {
  background: #f5f5f5;
}

.cont_loop.bg_gris h3 {
  font-family: inter;
  color: #160f29;
  font-weight: 600;
  font-size: 20px;
}

input.btn.btn_morado[type="file"] {
  position: absolute;
  height: 210px;
  left: 0;
  top: 0;
  text-align: center;
  opacity: 0;
}

.cont_loop.bg_descarga {
  position: relative;
  min-height: 210px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D9D9D9FF' stroke-width='2' stroke-dasharray='28%2c 30' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
}

.cont_loop.bg_descarga h3 {
  font-family: inter;
  color: #160f29;
  font-size: 20px;
  font-weight: 600;
}
.cont_loop.bg_descarga p,
.cont_loop.bg_descarg h3,
.margin0 {
  margin: 0 !important;
}

.cont_loop.sinFondo label {
  position: absolute;
  /*height: 49px;*/
  display: flex;
  align-items: center;
  font-family: inter;
  padding: 10px;
  color: #160f29;
  box-sizing: border-box;
  /*border: 1px dashed;*/
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D9D9D9FF' stroke-width='2' stroke-dasharray='5%2c 10' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
  width: 80%;
  border-radius: 15px;
}

.cont_delate,
.cont_loop .cont_flex_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
div#borrar {
  position: absolute;
  bottom: 27px;
  right: 10%;
  background: #ed4a29;
  color: #fff;
  padding: 8px;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

div.container_archivo div.borrar_archivo div#borrar{
  bottom: auto;
  right: auto;
}

div.container_archivo div.input_archivo input{
  width: 100%;
}

div.btn_borrar_archivo {
  position: absolute;
  bottom: 6px;
  right: 1%;
  background: #ed4a29;
  color: #fff;
  padding: 8px;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.container_btn_borrar{
  display: flex;
  justify-content: center;
  align-content: center;
}

div.container_sube_archivo input{
  width: 100%;
}

div.container_sube_archivo div{
  position: relative;
}

div.container_sube_archivo label{
  width: 100% !important;
}

div.container_archivo {
  display: grid;
  grid-template-columns: 80% 20%;
  margin-bottom: 0.5em;
}

div.container_archivo div.nombre_archivo label{
  position: relative;
  width: 100%;
  background-image: none;
  font-size: 0.9em;
}

div.container_archivo div.borrar_archivo{
  display: flex;
  justify-content: center;
  position: relative;
}

div.container_archivo div.borrar_archivo div.btn_borrar_archivo{
  bottom: auto;
  right: auto;
}

.archivo_seleccionado{
  font-size: 0.9em;
}

div#borrar svg {
  fill: #fff;
}
.cont_loop.sinFondo p {
  min-height: 89px;
}
.cont_flex.con_flex_wrap.pagoEvaluo .sinFondo {
  width: 100%;
}

.cont_flex.con_flex_wrap.resumen {
  border-top: 1px solid #ddd;
  margin-top: 1em;
  padding-top: 1em;
  align-items: center;
  margin-bottom: 2em;
}

#cualEnfermedad {
  width: 25rem !important;
}

.logoSocios {
  width: 5rem;
}

.logo_client {
  margin: 30% auto;
  max-width: 250px;
}

.logo_client img {
  width: 100%;
  height: auto;
  margin: 30% auto;
}

.logo-calculadora {
  width: 7rem;
  padding-left: 10rem;
}

.cont_logo {
  /*background: #fff;*/
  border-radius: 5px;
  margin: 20px 0;
  padding: 25px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
}
.cont_logo img {
  width: 10%;
  height: auto;
  margin-right: 20px;
}
.cont_logo h1 {
  margin-top: 25px;
}
/**NUEVOS 28-SEP-23**/
#informacion .cont_loop {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D9D9D9FF' stroke-width='2' stroke-dasharray='28%2c 30' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
}

.no_border,
.btn.btn_morado.btn_big {
  border: 0 !important;
}
.col12.cont_logo.Socio_img img {
  max-width: 200px;
  margin-bottom: 2em;
}

.col12.cont_logo.Socio_img img {
  max-width: 100px;
  margin-bottom: 2em;
}
.btn_bco,
.botones a.btn.btn_bco {
  background: #fff !important;
  /* padding: 10px !important;
  margin: 0; */
}

.col12.cont_logo.Socio_img {
  display: flex;
  align-content: center;
  align-items: center;
  /*justify-content: space-between;*/
}

/* Flecha de Documentos Vivienda */
.logo_mobil {
  width: 18px;
  height: 14px;
}

/*** 01/02/24 ***/
.cont_flexible {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
}

.cont_left {
  width: 25% !important;
}

.cont_right {
  width: 80%;
}

div#tablaComisiones {
  width: 90% !important;
  height: 100%;
}

div#msjClientesRef b,
div#msjCreditosOtorgados b,
div#msjCreditosOtorgados b {
  font-weight: 800;
  font-size: 27px;
}

/*-----------------------------Cambios JPB-------------------------------*/
.modal_pop_buro_cotizacion {
  /* overflow-y: scroll; */
  position: relative;
  left: 50%;
  width: 700px;
  /* height: 94%; */
  transform: translate(-50%, -95%);
  background-color: #fff;
  padding: 10px;
  z-index: 1000;
  text-align: center;
}

/* .modal_pop_buro_cotizacion {
  overflow-y: scroll;
  position: relative;
  left: 50%;
  width: 700px;
  height: 94%;
  transform: translate(-50%,-95%);
  background-color: #fff;
  padding: 10px;
  z-Index: 1000;
  text-align: center;
} */
/* .modal_pop_buro_cotizacion{
  position: relative;
  left: 50%;
  width: 700px;
  height: 506px;
  transform: translate(-50%,-82%);
  background-color: #fff;
  padding: 10px;
  z-Index: 1000;
  text-align: center;
} */

.centrar_card {
  transform: translate(-57%, -3%);
  justify-content: flex-end;
  width: 900px;
  height: 440px !important;
}

.separacionCards {
  margin: 10px;
  width: 95% !important;
  height: 50% !important;
}

.modal_pop_cotizacion {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  z-index: 1000;
  text-align: center;
  border-radius: 40px;
}

div.activo {
  background: #ccc;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

#cotizacionesBancosConSolicitud {
  left: 50%;
  top: 50%;
}

/* JPB Responsivo para telefono */
@media only screen and (max-width: 600px) {
  /*.modal_pop {
    width: 55% !important;
    top: 50%;
    left: 50%;
  }*/

  .cont_loop_solicitud {
    transform: translate(0%, -3%);
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 25px;
    min-height: 258px;
    margin-bottom: 3em;
  }

  .alto_pop {
    height:fit-content;
  }

  .modal_pop_buro_cotizacion {
    overflow-y: scroll;
    position: relative;
    /* top: 50%; */
    left: 63%;
    width: 100%;
    height: 97%;
    /* height: auto; */
    transform: translate(-61%, -56%);
    background-color: #fff;
    padding: 10px;
    z-index: 1000;
    text-align: center;
    border-radius: 10px;
  }

  /* .modal_pop_buro_cotizacion {
      position: relative;
      top: 50%;
      left: 63%;
      width: 50%;
      height: auto;
      transform: translate(-50%,-50%);
      background-color: #fff;
      padding: 10px;
      z-Index: 1000;
      text-align: center;
      border-radius: 10px;
  } */

  #cotizacionesBancosConSolicitud {
    left: 50%;
    top: 55%;
  }

  #info_buro_solicitud {
    margin-bottom: -15em !important;
  }

  #cotizacionesBancosConSolicitud {
    overflow-y: scroll;
  }
}

.anchoCardBancoSeleccionado {
  width: 80% !important;
  cursor: pointer;
}

div#buroResultadosBancos {
  justify-content: center;
}

.sinSolicitudes {
  padding-top: 35px;
}

.modal_pop_informacion_subida {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 30%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  z-index: 1000;
  text-align: center;
  border-radius: 40px;
}

/*JPB Cambios para carrusel*/
.modal_pop_banco {
  border: solid #9c36b2;
  position: relative;
  top: 50%;
  left: 50%;
  width: 4%;
  height: 500px;
  transform: translate(-63%, -80%);
  background-color: #fff;
  padding: 10px;
  z-index: 1000;
  text-align: center;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
}

.modal_pop_banco_aplicado {
  position: relative;
  top: 50%;
  width: 4%;
  height: 95%;
  /*Para cotizaciones no APLICADAS*/
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 10px;
  z-index: 1000;
  text-align: center;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
}

/* Agregar una media query para pantallas m?s peque?as (ej. dispositivos m?viles) */
@media only screen and (max-width: 1034px) {
  /* div#showBuroResults {
      transform: translate(-50%, -90%);        
  } */
  .modal_pop_banco {
    border: solid #9c36b2;
    position: relative;
    top: -7%;
    left: 50%;
    width: 4%;
    height: 95%;
    transform: translate(-50%, -60%);
    width: 88% !important;
    height: 490px !important;
    background-color: #fff;
    padding: 10px;
    z-index: 1000;
    text-align: center;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
  }

  .modal_pop_banco_aplicado {
    position: fixed;
    top: 70%;
    width: 4%;
    height: 95%;
    /*Para cotizaciones no APLICADAS*/
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 10px;
    z-index: 1000;
    text-align: center;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
  }

  .modal_pop_informacion_subida {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 30px;
    z-index: 1000;
    text-align: center;
    border-radius: 40px;
  }
}

/* APQ:31/05/2024 */
.cont_subidor_archivos{
  margin-top: 1em;
  align-items: flex-start;
}

#todasCotizaciones .btn {
  margin: 0 auto 10px;
  display: block;
  max-width: 100%;
}

.textoErrorCrearCuenta {
  color: white;
}

/* APQ: 12/06/2024*/
.renglon{
  display: flex;
  justify-content: space-between; 
  border-top: 1px solid #c6c6c6;
}

.renglon p.col_ini{
  text-align: left;
}

.renglon p.col_fin{
  text-align: right;
}

.font14{
  font-size: 14px;
}

.ancho100{
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 100% !important;
}

.estado_cotizacion{
  border-top: 1px solid var(--ColorBoton);
  padding: 10px 0;
  font-weight: bold;
}

.estado_cotizacion p{
  color: var(--ColorBoton);
  margin: 0;
  padding: 0;
}

.cont_informacion_btns{
  margin-bottom: 3em;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.cont_informacion_btns a.btn{
  width: 80%;
  min-width: 250px;
  max-width: 450px;
}

.contenido_centrado{
  justify-content: center;
}

.flex_start{
  align-items: flex-start;
}

/*APQ: 19/06/2024*/
/* Slider FINMAX */
.flex_centrado{
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-derecho{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.slider_cont{
  width: 40%;
  border-radius: 20px;

  .flecha_siguiente{
    width: auto;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;

    .icono_flecha{
      font-size: xx-large;
      color: #FF0000;
      margin: 0 !important;
    }

  }

  div:first-child{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slick-dots li button:before{
    color: #FFFFFF;
    font-size: 10px !important;
  }

  .slick-dots li.slick-active button:before{
    color: #FFFFFF;
  }
}

div.slider_card{
  box-sizing: border-box;
  padding: 2em;
  border-radius: 20px;
  background-color: #FFFFFF;
  border: 5px solid #FF0000;
  width: 96% !important;
  min-width: 200px;
  min-height: 300px !important;
  text-align: center;

  .cierra_carrusel{
    justify-content: end !important;
    
    button{
      border: 0;
      background-color: transparent;

      i{
        margin: 0 !important;
        font-size: 1.5em;
      }
    }
  }

  h1{
    font-size: x-large;
    font-weight: bolder !important;
    margin-bottom: 1em;
  }

  p{
    font-size: 1.3em;
    margin-top: 0;
    margin-bottom: 1em;
  }
  .video{
    width: 90%;
    max-width: 400px;
    min-height: 200px;
  }
}

div.slider_cont div.slider_card img.img_slider{
  width: 150px;
}

@media screen and (max-width: 1366px){
  .slider_cont{
    width: 60%;
  }

  #cont_grafica_invitaciones_bancos{
    grid-template-columns: 3fr 2fr !important;
  }
}

@media screen and (max-width: 600px) {
  .slider_cont{
    width: 90%;
  }

  .icono_flecha{
    display: none;
  }

  #cont_grafica_invitaciones_bancos{
    grid-template-columns: 1fr !important;
  }

  #graficas_clientes.grid3{
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    .grid_col1{
      grid-column: 2;
    }
  }

}

/*APQ: 02/07/2024*/
div.cont_resultados_buro div.modal_pop_buro{
  width: 100% !important;
  align-items: center;
}

.width_auto{
  width: auto !important;
}

/*APQ: 11/07/2024*/
.btn_documentacion{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;

  .main_text{
    min-width: 200px;
  }

  .sub_btn_doc{
    flex-grow: 1;
    text-align: end;
    margin-left: 0.5em;
  }
}

/*APQ: 08/07/2024*/
/*Popup que te permite escanear la información de tu INE*/

.loading_lector_INE,
.width50{
  width: 50% !important;
}

.flex_derecha,
.cierra_pop{
  display: flex;
  justify-content: end;
}

.cierra_pop{
  button{
    border: 0;
    background-color: transparent;
    padding: 0;

    i{
      margin: 0 !important;
      font-size: 1.8em;
    }
  }
}

.cont_pop{
  background-color: #FFFFFF;
  width: 35%;
  min-height: 60%;
  border-radius: 10px;
  padding: 2.5em;

  h2{
    text-align: center;
    margin: 0 0 1em;
  }

  .cont_img{
    margin-bottom: 1.5em;
  }

  /* Pantalla/Contenedor que muestra el QR */
  .cont_QR{
    margin-bottom: 1em;
    min-height: 256px;

    .IncodeDefaultComponentContainer{
      position: static !important;
      height: auto !important;
      width: auto !important;
      display: flex;
      justify-content: center;
      
      .IncodeRedirectQRCode{
        padding: 1em;
        background-color: #ECEFF2;
        border-radius: 10px;
      }
    }
  }

  ul li{
    list-style: disc;
    text-align:justify;
    color: #BEBEBE;
  }
}

div.cont_pop_btns{
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 2px;
  justify-items: center;

  button.btn{
    width: 100% !important;
  }
}

@media screen and (max-width: 600px) {
  .cont_pop{
    width: 50%;

    div.cont_pop_btns{
      grid-template-columns: auto;

      button.btn{
        margin-top: 0 !important;
      }
    }
    
  }
}

@media screen and (max-width: 500px) {
  .cont_pop{
    width: 70%; 
  }
}

.espera_incode{
  div{
    margin-bottom: 6.5em;
  }
}

button .estiloBotonVivienda
{
  border-radius: 10px;
  padding: 15px 20px !important;
  font-size: 13px;
  color: var(--ColorTexto);
  border: 1px solid var(--ColorTexto);
  font-family: "Inter";
  cursor: pointer;
  text-align: center;
}

/*  APQ:23/07/2024  
 *  INCODE
*/

.incode_cont{
  width: 100%;

  .IncodeDefaultComponentContainer{
    width: 100%;
  }

  .IncodeTutorialButton,
  .IncodeTutorialHeaderTitle{
    margin-bottom: 0;
  }

  .IncodeTutorialAnimation{
    height: calc(40vh);
  }

  .IncodeTutorialVideoselfie{
    width: 100%;
    height: auto;
  }

  div:has(.IncodeTutorialHeader){
    padding: 0px 5px 5px;
  }

  div.IncodeDefaultComponentContainer:has(video.IncodeCaptureIdWebcam){
    position: fixed !important;
  }

  div.IncodeDefaultComponentContainer:has(video.IncodeCaptureSelfieWebcam.selfie){
    position: fixed !important;
    background-color: #FFFFFF;
  }
}

/*
  APQ: 25/07/2024
  Calculadora Liquidez
*/

.credito_cotizar{
  display: grid;
  justify-content: space-between;
  column-gap: 1em;
  grid-template-columns: auto auto;

  label{
    display: flex !important;
    align-items: center;
    text-align: end;
  }

  p{
    display: flex;
    justify-content: space-between;
    width: auto;
  }
  
}

/*  APQ: 31/07/2024   */
.margintop_1em{
  margin-top: 1em !important;
}

.error_color{
  color: #FF0000;
}

/*
 * APQ: 02/08/2024
 * Calculadora Firmauto
*/

.calc_firmauto{
  li.cont_btn_firmauto{
    border-bottom: 0px !important;
  }

  form label{
    color: var(--textoCalculadora);
  }

  button{
    border: 0;
  }
}

button.btn_max_width{
  width: 100% !important;
  max-width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.modal {
  background-color: #fefefe;
  border: 1px solid #888;
  width: 25%; 
  padding: 2.5em;
  border-radius: 30px;
  box-shadow: 5px 5px 10px #333;
}

.modal form div.cont_btn_submit{
  margin-top: 1.5em;
}

@media screen and (max-width: 600px) {
  .modal{
    width: 60%;
  }
}

/*
* APQ: 29-08-2024
* Módulo Mi Hipoteca 
*/

.card_btn{
  position: relative;
  width: 300px;
  max-width: 300px;
  border-radius: 10px;
  margin: 2em 0;
  
  img{
    display: block;
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  p.overlay{
    position: absolute; 
    bottom: 0; 
    background: rgba(0, 0, 0, 0.4);
    color: #f1f1f1; 
    width: 100%;
    color: white;
    font-size: 18px;
    margin: 0;
    padding: 1em;
    border-radius: 0 0 10px 10px;
    text-align: center;
    box-sizing: border-box;
  }
}

.card_btn:hover,
.cursor-pointer{
  cursor: pointer;
}

div.copy_cont{
  display: block !important;
  text-align: center;
}

#cont_tabla_amortizacion div.ag-header-cell.ag-focus-managed{
  background-color: var(--ColorBoton);
  color: #FFF;
}

#cont_tabla_amortizacion div.ag-header-container{
  background-color: var(--ColorBoton);
}

.tabla_amort_aportacion_cell{
  border: 1px #858585 solid !important;
  border-radius: 10px;
}

#btnAmortizacionLote{
  margin: 0 !important;
  padding: 11px !important;
}

.grid3{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;

  .grid_col1{
    grid-column: 1;

    img{
      width: 100%;
    }
  }

  .grid_col2{
    grid-column: 2 / -1;

    p{
      text-align: justify;
    }
  }
}

.ancho_min{
  min-width: 250px !important;
}

/*
 * APQ: 20-09-2024
*/

.icon_btn{
  font-size: 2em;
  cursor: pointer;
}
.icon_btn:hover{
  color: var(--ColorBoton);
}

i.fa.restringido_grande{
  font-size: 7em;
  color: #FF0000;
}

form.inactivo input,
form.inactivo select{
  background: none;
  border: none;
  color: #45434d;
  opacity: 1;
  appearance: none;
}

/*
 * APQ: 23-09-2024
 * Toggle switch
*/

.btn-toggle {
  --bg: #f3f3f3;
  --btn-bg-inactive: #ffffff;
  --btn-bg-active: var(--ColorBoton);
  --border: #DCDCDC;
  --border-w: 1px;
  --btn-thumb: #EDF2F4;
  --btn-w: 3.5rem; 
  --btn-h: calc(var(--btn-w) / 2); 
  --gap: calc(var(--btn-h) / 10); 

  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  input {
    display: none;
  }

  .toggle {
    box-sizing: content-box;
    width: var(--btn-w);
    height: var(--btn-h);
    padding: var(--gap);
    border: var(--border-w) solid var(--border);
    border-radius: var(--btn-w);
    background: var(--btn-bg-inactive);
    cursor: pointer;
    position: relative;
    transition: background 0.3s;
  }

  .toggle::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: var(--gap);
    width: var(--btn-h);
    height: var(--btn-h);
    border: var(--border-w) solid var(--border);
    border-radius: 50%;
    background: var(--btn-thumb);
    transition: transform 0.3s;
  }

  [id^="checkRecordatorio"]:checked + .toggle::before {
    transform: translateX(calc(var(--btn-w) - var(--btn-h)));
  }

  [id^="checkRecordatorio"]:checked + .toggle {
    background: var(--btn-bg-active);
  }
}

.cont-toggle,
.cont-flex-gap{
  display: flex;
  gap: 1em;
}

.cont-flex-column{
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.nota_inferior p{
  font-size: small;
  text-align: justify;
}

/*
 * APQ: 08-10-2024
*/

.cont_grid{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1em;
}

.max_width150px{
  max-width: 150px;
}

.cont_inline_flex{
  display: inline-flex;
}

.gap_small{
  gap: 0.5em !important
}

.bg_alternativo.btn_morado_personalizado,
.bg_alternativo.btn{
  background-color: var(--ColorAlternativo) !important;
}

/*
 * APQ: 18-10-2024
*/
.display_renglon{
  display: inline-flex;
  align-items: center;
  gap: 1em;

  *{
    margin: 0;
  }
}

.fuente_secundaria{
  font-family: var(--FuenteSecundaria);
  color: var(--ColorTexto);
}

.sin_ruptura{
  white-space: nowrap;
}

.grid-160px-1fr,
.grid3_modal{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: 1em;
}

div.modal.width50{
  max-height: 80%;
}

div.modal .info_cont_scrollable{
  margin-bottom: 0 !important;
  max-height: 70vh;
  width: 100%;
  padding: 0px 25px 25px 25px;
  box-sizing: border-box;
  border-radius: 25px;
  margin-bottom: 3em;
  overflow-y: auto;
  overflow-x: hidden;
}

a.btn.btn_bco:hover{
  color: var(--ColorHoverBtn) !important;
  background-color: var(--ColorBoton) !important;
}

.tooltip_normal{
  max-width: 200px;
}

.tooltip_icon{
  color: var(--textoCalculadora) !important;
  font-size: 1em !important;
}

/*APQ: 31-10-2024*/

.card_contenedor{
  box-sizing: border-box;
  background-color: #FFFFFF;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  padding: 1.2em 1em;
}

.min_height200{
  min-height: 200px;
}

.leyenda{
  font-size: small;
}

.height200px,
.grafico_invitaciones{
  height: 200px;
}

.grafica_color_empresa{
  fill: var(--ColorGrafica);
}

#leyenda_invitaciones_bancos{
  height: 200px;
  width: 200px;
}

#cont_grafica_invitaciones_bancos{
  display: grid;
  grid-template-columns: 2fr 1fr;
}

#graficas_invitaciones.cont_grid{
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
}

div.herramientas_tabla{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.5em;
}

button.link{
  border: none;
  background-color: rgba(0, 0, 0, 0);

  i.fa{
    font-size: 1.4em;
  }
}
button.link.inactivo{
  color: #000;
  cursor:default;
}

/*
  APQ: 19-11-2024
*/

.loader {
  width: fit-content;
  font-weight: bold;
  clip-path: inset(0 2ch 0 0);
  animation: l4 1s steps(4) infinite;
}
.loader::before{
  content:"..."
}
@keyframes l4 {to{clip-path: inset(0 -1ch 0 0)}}

.flex_space_around{
  justify-content: space-around;
  align-items: center;
  gap: 1em; 
}

button.btn_cuadrado{
  border-radius: 10px;
  padding: 0.7em 1em;
  border: none;
}

button.color_empresa{
  background-color: var(--ColorBoton);
  color: #FFF;
}

div.cont_tab{
  display: flex;
  gap: 1em;

  .tab_contenido{
    width: 70%;
  }

  .tabs{
    width: 25%;
  
    /* Style the buttons that are used to open the tab content */
    button {
      display: block;
      background-color: inherit;
      color: black;
      padding: 22px 16px;
      width: 100%;
      border: none;
      outline: none;
      text-align: left;
      cursor: pointer;
      transition: 0.3s;
    }
  
    /* Change background color of buttons on hover */
    button:hover {
      background-color: color-mix(in srgb, var(--ColorBoton) 70%, #FFF 30%);
    }
  
    /* Create an active/current "tab button" class */
    button.active {
      color: #FFF;
      background-color: var(--ColorBoton);
    }
  }

}

.cont_scroll{
  max-height: 70vh;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}

.cont-scroll-x{
  width: 100%;
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: auto;
}

#estatusSolicitudCredito{
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  gap: 0.5em;

  p{
    margin: 0;
    padding: 10px;
    background: #e6e6e6;
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 100%;
  }

  p.active{
    width: auto;
    white-space: nowrap;
    border-radius: 20px;
    color: #fff;
    background-color: var(--ColorBoton);
  }

  p.bg-active{
    color: #fff;
    background-color: var(--ColorBoton);
  }
}

/*
 *APQ: 02-12-2024
*/
div.progress{
  width: 80%;
  padding: 2px 2px;
  height: 21px;
  border-radius: 5px;
  border: solid 2px #4e4e4e;
  background-color: #FFF;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 2px;
}

div.bar{
  background-color: var(--ColorBoton);
  border-radius:2px;
}

.height100{
  height: 100%;
}

.manita_cont{
  position: relative;
  overflow: visible;
}

.manita_click {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 90%; 
  transform: translateX(-50%); 
  z-index: 2; 
  pointer-events: none;
  animation: showMessage 1s forwards, clickLoop 1.5s ease-in-out infinite;
}
#confirmarResultadosCalculadoraMovil .manita_click{
  left: 70%;
}

@keyframes clickLoop {
  0%, 100% {
    transform: scale(1);
    top: -20px;
  }
  50% {
    transform: scale(0.9);
    top: -10px;
  }
}

.cont_msg{
  padding: 1em;
  border: 2px solid #FFF;
  border-radius: 20px;
  text-align: center;
  opacity: 0;
  transform: scale(0.8);
  animation: showMessage 1s forwards, highlight 1.5s infinite 1.5s;
}

@keyframes showMessage {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes highlight {
  0%, 100% {
    box-shadow: 0 0 3px 1px #FFF;
  }
  50% {
    box-shadow: 0 0 5px 2px #FFF;
  }
}

/*
 * APQ: 03-12-2024
 * Más detalles hipoteca
*/

.btn_collaps {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 1em;
  text-align: center;
}

.active, .btn_collaps:hover {
  background-color: #ccc;
}

.collaps_contenido {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #f1f1f1;
}

.circulo{
  display: flex;
  align-items: center;
  margin: 0;
  padding: 10px;
  background: var(--ColorGrafica);
  color: #FFF;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.icono_grafica {
  font-size: 50px;
  background: linear-gradient(to top, var(--ColorGrafica) 0%, #D9D9D9 0%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.flex_justificado_centro{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.grid50_50{
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1em;
}

/*
 * APQ: 23-12-2024
 * Arbol de archivos
*/

#arbol-archivos{
  background-color: #f5f5f5;
  padding: 1em 1em;
  border-radius: 20px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  --unidad: 10px;

  .my-1{
    margin-top: var(--unidad);
    margin-bottom: var(--unidad);
  }

  .pl-2{
    padding-left: calc(2 * var(--unidad));
  }

  i.fa{
    color: var(--ColorBoton);
  }

  ul li{
    cursor: pointer;
  }

  .fe-archivo{
    display: grid;
    grid-template-columns: 1fr auto;
    margin: 2px;
  }

  li button.btn-icon{
    border: none;
    background-color: transparent;
    padding: 1px;
    margin: 0;
    width: 100%;
    text-align: left;
    font-size: 0.9em;
  }

  .fe-modal{
    background-color: transparent !important;
    cursor: default;
  }

  .fe-modal .modal{
    box-shadow: 2px 2px 3px #333 !important;
    padding: 1.5em !important;
  }

  div.fe-modal form div.form-btns{
    display: inline-flex;
    gap: 1em;
    margin-top: 0.5em;
  }

  ul.dropdown-opciones{
    position: absolute;
    top: 100%;
    right: 110%;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    list-style: none;
    margin: 0;
    z-index: 1000;
  }
}

/*
  APQ: 10-01-2025
*/

button.btn.btn-sin-estilo{
  border: 0px;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  text-align: left;
}

.fs-16{
  font-size: 16px !important;
}

.fs-11{
  font-size: 11px !important;
}

.align-items-center{
  align-items: center;
}

div#form-etapas-ejecutivo-cliente{
  --altura: 350px;
  --padding-contenido: 10px;

  height: var(--altura);
  overflow-y: scroll;
  overflow-x:hidden;
  padding: var(--padding-contenido);

  .cont-contenido-barra{
    display: grid;
    grid-template-columns: 80% 20%;
  
    aside.barra-lateral{
      border-left: 2px solid #ddd;
      overflow: auto;
      position: sticky;
      top: 0;
      height: var(--altura);
      padding: var(--padding-contenido);
    }
  
    div.cont-contenido{
      padding: var(--padding-contenido);
    }

    div.flex-renglon{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.cont2cards{
  display: grid;
  grid-template-columns: minmax(200px, 40%) minmax(200px, 40%);
  grid-template-rows: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1em;
}

button.btn.cuadrado{
  border-radius: 10px !important;
  border: 1px solid var(--ColorTexto) !important;
}

.no-text-select{
  user-select: none;
}

.modal.wrapper {
  width: 80%;
  height: 80%; 
}

button.btn-tab{
  padding: 0.8em 1.5em;
  border: 0;
  border-radius: 10px;
  background-color: #e6e6e6;
  font-family: var(--FuenteSecundaria);
}

button.btn-tab.active{
  color: #FFF;
  background-color: var(--ColorBoton);
}

button.btn.btn-input-file{
  background-color: transparent;
  color: var(--ColorTexto);
  font-family: var(--FuenteSecundaria);
  font-size: 0.9em;
  font-weight: 600;
  text-align: start;
  text-indent: 10px;
  overflow: hidden;
  padding: 10px;
  border-radius: 10px;
  border: 0;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D9D9D9FF' stroke-width='2' stroke-dasharray='5%2c 10' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
}

button.btn_morado.circulo{
  margin: 0 auto;
  width: 40px !important;
  padding: 0 !important;
  border-radius: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
}

.cont-cards-archivos{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 45%));
  gap: 1em;
}

#card-archivo{
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  min-height: 258px;
  border: 1px solid #d9d9d9;
  position: relative;

  h3{
    color: var(--ColorTexto);
    font-family: "inter";
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin: 0 0 10px;
  }

  p{
    min-height: 89px;
    margin: 0;
    font-family: var(--FuenteSecundaria), sans-serif;
    color: var(--ColorTexto);
  }

  label{
    position: absolute;
    display: flex;
    align-items: center;
    font-family: "inter";
    padding: 10px;
    color: var(--ColorTexto);
    box-sizing: border-box;
    background-image: url("data:image/svg+xml,%3csvg width=%27100%25%27 height=%27100%25%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3crect width=%27100%25%27 height=%27100%25%27 fill=%27none%27 stroke=%27%23D9D9D9FF%27 stroke-width=%272%27 stroke-dasharray=%275%2c 10%27 stroke-dashoffset=%272%27 stroke-linecap=%27square%27/%3e%3c/svg%3e");
    width: 70%;
    border-radius: 15px;
    overflow: hidden;
    word-wrap: break-word;
    white-space: pre-wrap;
    max-height: 120px;
    text-indent: 10px;
  }

  button{
    width: 100%;
  }
}

.fuente-secundaria{
  font-family: var(--FuenteSecundaria), sans-serif;
  color: var(--ColorTexto);
}

/* APQ: 25-02-2025: Kanban */

.cont-kanban{
  display: grid;
  grid-template-columns: repeat(6, minmax(150px, 1fr));
  gap: 0.2em;
  user-select: none;
}

.col-kanban{
  display: grid;
  grid-template-rows: 1fr auto;
  background-color: #FAFAFA;
  width: 100%;
  max-height: 500px;
  padding: 1em 1em 1em 0;
  box-sizing: border-box;

  .cont-el-kanban{
    padding: 8px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 0.5em;
    height: clamp(2em, 30vh, 20em);
    overflow-y: auto;
    overflow-x: hidden;
  }

  ::-webkit-scrollbar{
    width: 0.4em;
  }

  ::-webkit-scrollbar-thumb{
    background-color: #C1C1C1;
    border-radius: 100vw;
  }

  @supports(scrollbar-color: dark transparent){
    *{
      scrollbar-color: #C1C1C1 transparent;
      scrollbar-width: thin;
    }
  }
  
}

.col-header-kanban{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #858585;
  border-radius: 10px;
  padding: 4px;
  margin-bottom: 10px;
}

h2.col-titulo-kanban{
  margin-block: 0 5px;
  text-align: center;
  font-size: 1em;
  font-weight:lighter;
}

.el-kanban{
  min-height: fit-content;
  max-height: 1.2em;
  background-color: #ffffff;
  padding: 9px 6px;
  margin: 6px 0;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
}

.el-notificacion-kanban{
  position: relative;

  i {
    position: absolute;
    top: -10px; 
    left: -10px;
    color: #00A884;
  }
}

.el-kanban:hover{
  cursor: grab;
}

.el-kanban.estatico:hover{
  cursor: pointer;
}

h3.el-titulo-kanban{
  font-family: var(--FuenteSecundaria), sans-serif;
  margin: 0;
  font-size: 0.7em;
  font-weight: normal;
  white-space: nowrap;
  overflow-x: hidden;
  text-transform: uppercase;
}

h2.titulo2-bg{
  margin-bottom: 0;
  padding: 2px;
  background-color: var(--ColorBoton);
  color: var(--ColorHoverBtn);
  font-weight: normal;
  text-align: center;
  font-size: 1.5em;
  border-radius: 10px;
}

.el-contenido-kanban{
  margin-block: 0.4em;
  overflow: hidden;

  p{
    margin: 0;
    font-size: 0.6em;
    font-weight: bold;
  }
}

.cont_sp_contenido{
  margin: 5% 10%;
}

.cont-tooltip{
  p{
    margin-block: 0 2px;
    color: #FFF !important;
    font-size: smaller;
  }
}

/* APQ: 25-02-2025: Chat Whatsapp ejecutivo */

#chat-panelIzquierdo {
  width: 25%;
  border-right: 1px solid #4a5568; 

  .sticky {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: var(--left-panel-bg); 
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f1f1f1;
    padding: 0.75rem;
    height: 35px;
  }

  .header .icons {
    display: flex;
    align-items: center;
    gap: 0.75rem; 
  }

  .search-container {
    padding: 0.75rem; 
    display: flex;
    align-items: center;
  }

  .search-wrapper {
    position: relative;
    height: 2.5rem; 
    margin-left: 0.75rem; 
    margin-right: 0.75rem; 
    flex: 1;
  }

  .search-icon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: #6b7280;
    z-index: 10;
  }

  .filter-icon {
    cursor: pointer;
  }

  .chat-list {
    margin-bottom: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 0;
    max-height: 80%;
    overflow: auto;
  }

  .no-conversations {
    text-align: center;
    color: #6b7280;
    font-size: 0.875rem;
    margin-top: 0.75rem;
  }

}

#chat-panelDerecho{
  width: 75%;
  display: flex;
  flex-direction: column;
  
  .panel-header {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 50;
  }
  
  .header-container {
    display: flex;
    justify-content: space-between;
    background-color: #f1f1f1;
    padding: 12px;
    height: 35px;
  }
  
  .header-left {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .avatar-fallback {
    animation: pulse 1.5s infinite;
    background-color: #4b5563;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  
  .conversation-name {
    display: flex;
    flex-direction: column;
  }
  
  .header-right {
    display: flex;
    align-items: center;
    gap: 28px;
    margin-right: 20px;
  }
  
  .video-link {
    display: block;
  }
  
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  
}

#chat-conversacion{
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 6px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  
  .conversacion-online {
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    background-color: #22c55e;
    border-radius: 50%;
    border: 2px solid #ffffff;
  }
  
  .avatar-fallback {
    animation: pulse 1.5s infinite;
    background-color: #4b5563;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  
  .conversacion-header {
    display: flex;
    align-items: center;
  }
  
  .conversacion-name {
    font-size: 12px;
    font-weight: 500;
    font-family: var(--FuenteSecundaria);
  }
  
  .conversacion-time {
    font-size: 10px;
    color: #6b7280;
    margin-left: auto;
  }
  
  .conversacion-message {
    font-size: 12px;
    margin-top: 4px;
    color: #6b7280;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }  
}

.color-notificacion-chat{
  color: #00A884 !important;
}

span.notificacion-nuevo-msg {
  width: 12px;
  height: 12px;
  background-color: #00A884;
  border-radius: 50%;
}

#chat-conversacion:hover,
#chat-conversacion.seleccionado{
  background-color: #f1f1f1;
}

.conversacion-separador {
  height: 1px;
  margin: 0 10px;
  background-color: #1f2937;
  border: 0;
}

#chat-placeholder{
  width: 75%;
  background-color: #f5f5f5;
  display: grid;
  grid-template-rows: 1fr auto;
  padding: 40px 0;

  .chat-placeholder-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 40px 0;
    gap: 16px;
  }
  
  .chat-placeholder img {
    width: 320px;
    height: 188px;
  }
  
  .chat-placeholder-title {
    font-size: 1.875rem;
    font-weight: 200;
    margin-top: 20px;
    margin-bottom: 8px;
  }
  
  .chat-placeholder-text {
    width: 50%;
    text-align: center;
    color: #1f2937;
    font-size: 14px;
    opacity: 0.7;
  }
  
  .chat-placeholder-button {
    border-radius: 9999px;
    margin: 20px 0;
    background-color: #25d366;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
  }
  
  .chat-placeholder-button:hover {
    background-color: #1ebe57;
  }
  
  .chat-placeholder-footer {
    margin-top: auto;
    text-align: center;
    color: #1f2937;
    font-size: 12px;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  
}

#chat-contenedor-mensajes{
  position: relative;
  padding: 12px;
  flex: 1;
  overflow: auto;
  height: 100%;
  
  .message-list {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
  }

  .cont-mensaje {
    display: flex;
    margin-bottom: 10px;
    gap: 4px; 
    width: 66.66%;
  }
  
  .burbuja-mensaje {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 20;
    max-width: fit-content;
    padding: 4px 8px; 
    border-radius: 6px; 
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2); 
  }

  .burbuja-mensaje-propia {
    display: flex; 
    position: relative; 
    z-index: 20; 
    max-width: fit-content; 
    padding: 4px 8px; 
    border-radius: 6px; 
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2); 
    margin-left: auto; 
  }

  .bg-otro-mensaje {
    background-color: #ffffff; 
  }

  .indicador-otro-mensaje {
    position: absolute;
    top: 0;
    left: -4px;
    width: 12px; 
    height: 12px; 
    border-bottom-left-radius: 100%;
  }

  .bg-mensaje-propio {
    background-color: #f1f1f1;
  }

  .indicador-mensaje-propio {
    position: absolute;
    top: 0;
    right: -3px;
    width: 12px; 
    height: 12px; 
    border-bottom-right-radius: 100%;
    overflow: hidden;
  }

  .mensaje-texto {
    margin-block: 0;
    margin-right: 8px; 
    font-size: 14px; 
    font-weight: 300;
    white-space: pre-line;
  }
  
  .mensaje-texto-link {
    margin-right: 8px; 
    font-size: 14px; 
    font-weight: 300; 
    color: #60a5fa; 
    text-decoration: underline;
  }

  .hora-mensaje {
    font-size: 10px; 
    margin: 4px 0 0 0; 
    align-self: flex-end; 
    display: flex;
    gap: 4px; 
    align-items: center;
  }
  
  .cont-mensaje-imagen{
    position: relative;
    width: 250px;
    height: 250px;
    margin: 2px;
    overflow: hidden;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.25rem;
    }
  }

  .cont-mensaje-documento{
    position: relative;
    max-width: 250px;
    margin: 2px;
    overflow: hidden;

    a{
      color: var(--ColorTexto);
      text-decoration: none;
      border: #f1f1f1 1px solid;
      border-radius: 0.25rem;
      padding: 0.5rem;
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      gap: 2em;
    }

    .cont-doc-data{
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      gap: 0.5em;

      p{
        overflow-x: hidden;
        white-space: nowrap;
        box-sizing: border-box;
        text-overflow: ellipsis;
        font-size: 14px;
      }
    }
  }

  .cont-mesnaje-audio{
    position: relative;
    width: 250px;
  }
}

#chat-input-mensaje{
  background-color: #f1f1f1; 
  padding: 8px;
  display: flex;
  gap: 16px;
  align-items: center;
  
  .message-input-icons {
    position: relative;
    display: flex;
    gap: 8px;
    margin-left: 8px;
  }
  
  .message-input-form {
    width: 100%;
    display: flex;
    gap: 12px;
  }
  
  .message-input-field {
    flex: 1;
  }

  .icono{
    color: #000;
  }
  
  .message-input {
    padding: 8px;
    font-size: 14px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    background-color: #fff; 
    border: none;
    outline: none;
  }
  
  .message-input:focus {
    box-shadow: none; 
  }
  
  .message-input-actions {
    margin-right: 16px;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .message-input-button {
    background-color: transparent;
    color: #ffffff; 
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .message-input-button:hover {
    background-color: transparent;
  }
}

.chat-alerta{
  background-color: hsla(0, 0%, 95%, 0.1);
  padding: 3px 6px;

  .chat-alerta-texto{
    font-family: var(--FuenteSecundaria);
    font-size: 12px;
  }
}

#chat-indicador-fecha p{
  font-size: 0.875rem;
  color: #6b7280;
  margin-bottom: 0.5rem;
  padding: 0.25rem;
  z-index: 20;
  border-radius: 0.375rem;
  background-color: #f1f1f1;
}

/* APQ: 10-03-2025: Notas*/

.card-nota{
  background-color: #f1f1f1;
  padding: 6px 12px;

  .nota-header{
    display: flex;
    justify-content: space-between;
  }

  span.nota-autor, span.nota-fecha{
    font-size: 10px;
    color: #6b7280;
  }

  .nota-cont-texto{
    display: flex;
    flex-direction: column;
    gap: 0.2em;
  }

  p.nota-texto{
    font-size: 14px;
    margin-block: 4px;
    color: #6b7280;
    word-wrap: break-word;
  }

  textarea.nota-texto{
    background-color: #fafafa;
    margin-block: 4px;
    resize: none;
    white-space: pre-wrap;
    outline: none;
    border: none;
    font-family: var(--FuenteSecundaria);
  }

  .nota-cont-btns{
    display: flex;
    justify-content: end;
    gap: 2px;
  }
}

.padding0{
  padding: 0;
}

/* APQ: 18-03-2025 */
.form-select{
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background: #e6e6e6;
}

.form-textarea{
  width: 100%;
  box-sizing: border-box;
  background-color: #e6e6e6;
  margin-block: 4px;
  padding: 6px 4px;
  resize: none;
  white-space: pre-wrap;
  outline: none;
  border: none;
  border-radius: 10px;
  font-family: var(--FuenteSecundaria);
}

.spinner{
  width: 24px;
  height: 24px;
  border: 5px solid #f1f1f1;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.cont-grid-1fr-auto{
  display: grid;
  grid-template-columns: 1fr auto;
  justify-items: center;
}

.align-baseline{
  align-items: baseline;
}

button.btn.none{
  background: none;
  border: none;
  padding: 0;
}

#reproductor-audio {
  padding: 8px;
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
  gap: 10px;
  border: #f1f1f1 solid 1px;
  border-radius: 100px;
  background-color: #f1f1f1;

  input[type="range"]{
    background-color: transparent;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    height: 16px;
    -webkit-appearance: none;
    color: #f1f1f1;
    margin-top: 0px;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 8px;
    height: 16px;
    border-radius: 50%;
    background: #160f29;
    cursor: pointer;
    box-shadow: -300px 0px 0 300px #8B8B8B;
  }
}