@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: "Gotham";
  src: url("../styles/fonts/GothamBook.woff2") format("woff2"), url("../styles/fonts/GothamBook.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Geomanist";
  src: url("../styles/fonts/GeomanistRegular.woff2") format("woff2"),
       url("../styles/fonts/GeomanistMedium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Geometos";
  src: url("../styles/fonts/GeometosNeue.woff2") format("woff2"),
       url("../styles/fonts/GeometosNeueBold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Aptos";
  src: url("../styles/fonts/Aptos.woff2") format("woff2"),
       url("../styles/fonts/Aptos-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../styles/fonts/Montserrat-Regular.woff2") format("woff2"),
       url("../styles/fonts/Montserrat-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

root {
  --ColorBoton: #9c36b2;
  --ColorTexto: #160f29;
  --BotonGris: #45434d;
  --botonSwal: #160f29;
  --fondoCalculadora: #160f29;
  --textoCalculadora: #9c36b2;
  --FuentePrincipal: "DM Serif Display", serif;
  --FuenteSecundaria: "Inter", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--FuentePrincipal);
}

h1 {
  font-size: 3em;
  line-height: 1;
  margin-top: 0;
  color: var(--ColorTexto);
  font-weight: 400 !important;
}

p {
  font-family: var(--FuenteSecundaria), sans-serif;
  color: var(--ColorTexto);
}

p.col6 {
  padding: 0 !important;
}

small {
  font-family: var(--FuenteSecundaria);
  line-height: 20px;
}

.leyeda {
  font-family: var(--FuenteSecundaria);
  line-height: 20px;
}

small a {
  font-weight: 700;
}

.fa {
  margin-right: 14px;
}

.fa-classic,
.fa-light,
.fa-regular,
.fa-solid,
.fa-thin,
.fal,
.far,
.fas,
.fat {
  font-family: "Font Awesome 6 Pro";
}

.fa-grid-2:before {
  content: "\e196";
}

.fa-memo:before {
  content: "\e1d8";
}

.fa-check-circle:before,
.fa-circle-check:before {
  content: "\f058";
}

.fa-circle-exclamation:before,
.fa-exclamation-circle:before {
  content: "\f06a";
}

i.fa.fa-circle-check {
  color: #d9d9d9;
}

button.btn.btn_morado {
  width: 50%;
  max-width: 200px;
  border: 0;
}

.Resum.cont-Cotizaciones .bg_morado2 a.btn_morado {
  width: auto !important;
}

.dosColumns.cont_flex .btn_morado {
  display: block;
  width: 75%;
  text-align: center;
}

.btn_morado .bg_morado2 {
  width: 100%;
  display: block;
}

.bg_morado2 a.btn_morado {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  margin-top: 1em;
}

.btn_morado_personalizado {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  margin-top: 1em;
  border: 0;
}

button.btn.btn_morado {
  width: 50%;
  max-width: 200px;
  border: 0;
}

.btn,
button.btn.btn_bco,
button.btn.btn_morado {
  box-sizing: border-box;
  width: -moz-max-content;
  width: max-content;
}

input.btn.btn_morado[type=file] {
  margin: 0;
}

input.btn.btn_morado[type=file] {
  background: no-repeat;
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  border: 1px dashed #333;
}

input.btn.btn_morado[type=file] {
  position: absolute;
  height: 210px;
  left: 0;
  top: 0;
  text-align: center;
  opacity: 0;
}

/*--------------------------------- */
/* Clases que estarán modificadas mediante JS */
/*--------------------------------- */
.btn_morado {
  background: var(--ColorBoton) !important;
  border-radius: 10px;
  padding: 15px 20px !important;
  font-size: 13px;
  color: #fff !important;
  display: inline-block;
  text-decoration: none;
  margin: 0 10px;
}

.campo-form .btn_morado{
  width: 100% !important;
}

.btn_personalizado a {
  border-radius: 10px;
  padding: 15px 20px !important;
  font-size: 13px;
  color: var(--ColorTexto);
  border: 1px solid var(--ColorTexto);
  font-family: "Inter";
  cursor: pointer;
  text-align: center;
}

.btn_personalizado {
  width: 15% !important;
  background: var(--textoCalculadora) !important;
  border-radius: 10px;
  padding: 15px 20px !important;
  font-size: 13px;
  color: #fff !important;
  display: inline-block;
  text-decoration: none;
  margin: 0 10px;
}

.btn_morado_personalizado {
  background: var(--textoCalculadora) !important;
  border-radius: 10px;
  padding: 15px 20px !important;
  font-size: 13px;
  color: #fff !important;
  display: inline-block;
  text-decoration: none;
  margin: 10px auto;
}

.btn_regresar {
  width: 15% !important;
  border-radius: 10px;
  font-size: 13px;
  color: var(--ColorBoton) !important;
  display: inline-block;
  margin: 0 10px;
}

a.btn.btn_gris:hover {
  background: var(--ColorBoton);
  color: #fff;
}

.btn_bco {
  background: #fff;
  border-radius: 10px;
  padding: 15px 20px !important;
  border: 1px solid var(--ColorBoton);
  color: var(--ColorBoton) !important;
}

a.link {
  color: var(--ColorBoton);
  font-family: var(--FuenteSecundaria);
  font-size: 12px;
  margin: 1em 0;
}

div.menu-politicas a.link{
  color: white;
  font-family: var(--FuenteSecundaria);
  font-size: 10.24px;
  margin: 1em 0;
}

.link {
  color: var(--ColorBoton);
  font-family: var(--FuenteSecundaria);
  text-decoration: none;
}

a.back {
  color: var(--ColorBoton);
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px !important;
  display: block;
}

form label {
  display: block;
  font-family: var(--FuenteSecundaria);
  margin-bottom: 10px;
  color: var(--ColorBoton);
  padding-top: 15px;
}

ul.check a {
  color: var(--ColorTexto);
}

/** Botones **/
.bg_gris2 {
  height: 100%;
  box-sizing: border-box;
  padding: 32px;
  background: var(--BotonGris);
  border-radius: 30px;
  color: #fff;
  box-shadow: 10px 10px 30px 0px var(--ColorTexto) 40;
}

.button_YN { /* Safari */
  transition-duration: 0.4s;
  border-radius: 10px;
  padding: 15px 20px !important;
  font-size: 13px;
  border: 1px solid var(--ColorTexto);
  font-family: "Inter";
  display: inline-block;
  text-decoration: none;
}

a.btn {
  border-radius: 10px;
  padding: 15px 20px !important;
  font-size: 13px;
  color: var(--ColorTexto);
  border: 1px solid var(--ColorTexto);
  font-family: "Inter";
  cursor: pointer;
  text-align: center;
}

.btn_nuevo,
.bg_morado2 {
  background: var(--fondoCalculadora);
  border-radius: 10px;
  color: #fff !important;
}

.cont_flex .col6 {
  box-sizing: border-box;
}

/** Menú Lateral **/
.menu_lateral ul li a.active {
  background: var(--ColorBoton);
  color: #fff;
}

.menu_lateral .copyright p a,
.menu_lateral_pop .copyright p a {
  color: var(--ColorBoton);
  font-size: 100%;
}

.menu_lateral ul li a {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  box-sizing: border-box;
  margin-bottom: 15px;
  background: #fafafa;
  border-radius: 10px;
  color: var(--ColorTexto);
}

.menu_lateral_pop ul li a {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  box-sizing: border-box;
  margin-bottom: 50px;
  background: #f0f0f0;
  border-radius: 10px;
  color: var(--ColorTexto);
  font-size: 18px;
}

/** Aprobado **/
.col6.cont_img .bg_morado2 {
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  margin-bottom: 1em;
  padding: 50px;
  box-shadow: 10px 10px 30px 0px var(--ColorTexto) 40;
}

i.fa.fa-circle-exclamation {
  color: #fff;
  font-size: 1.4em;
}

/** Documentos Enviados **/
.bg_verde {
  padding: 30px !important;
  background-color: #8bbf21 !important;
  border-radius: 30px;
  /* height: 100%; */
  color: var(--ColorTexto);
}

.col6.bg_verde a {
  background: var(--ColorTexto);
  color: #fff;
  margin: 1em 0;
  display: inline-block;
  text-decoration: none;
}

.cont_loop.sinFondo h3 {
  color: var(--ColorTexto);
  font-family: "inter";
  font-size: 20px;
  font-weight: 600;
}

.cont_loop.bg_descarga h3 {
  font-family: inter;
  color: var(--ColorTexto);
  font-size: 20px;
  font-weight: 600;
}

.cont_loop.bg_gris h3 {
  font-family: inter;
  color: var(--ColorTexto);
  font-weight: 600;
  font-size: 20px;
}

.cont_loop.sinFondo label {
  position: absolute;
  display: flex;
  align-items: center;
  font-family: "inter";
  padding: 10px;
  color: var(--ColorTexto);
  box-sizing: border-box;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D9D9D9FF' stroke-width='2' stroke-dasharray='5%2c 10' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
  width: 70%;
  border-radius: 15px;
  overflow: hidden;
  word-wrap: break-word;
  white-space: pre-wrap;
  max-height: 120px;
  text-indent: 10px;
}

.cont_loop h3 {
  color: var(--ColorBoton);
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 10px;
}

.col6.simulador label {
  color: var(--textoCalculadora);
  display: block;
}

/** Información **/
#informacion h3 {
  font-size: 20px;
  color: var(--ColorTexto);
  font-family: inter;
  font-weight: 600;
}

span.select {
  height: 5px;
  background: linear-gradient(45deg, var(--ColorBoton) 50%, #d9d9d9 50%);
  width: 100%;
  display: block;
  margin: auto;
  border-radius: 10px;
}

span.select:before {
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  background: var(--ColorBoton);
  margin: -8px auto;
  border-radius: 100%;
}

span.lines {
  width: 100%;
  height: 2px;
  background: var(--ColorBoton);
  display: block;
  position: relative;
}

span.lines:before,
span.lines:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: var(--ColorBoton);
  margin: 8px 0;
  position: absolute;
}

.botonAplicarGrande {
  width: 80%;
  background: var(--ColorBoton);
  border-radius: 10px;
  padding: 15px 20px !important;
  font-size: 13px;
  color: #fff !important;
  border: 1px solid var(--ColorTexto);
  font-family: var(--FuenteSecundaria);
  display: inline-block;
  text-decoration: none;
}

.btn_plazo_selected {
  background-color: var(--ColorBoton);
  font-weight: bold;
}

input[type=range]::-moz-range-progress {
  background-color: var(--ColorBoton);
}

input[type=range]::-ms-fill-lower {
  background-color: var(--ColorBoton);
}

.precio_plazo button:hover,
.precio_plazo button.btn_plazo_selected {
  color: #fff;
  background: var(--ColorBoton);
}

.btn_morado_modal {
  background: var(--ColorBoton);
  border-radius: 10px;
  padding: 15px 20px !important;
  font-size: 15px;
  color: #fff !important;
  border: 1px solid var(--ColorBoton);
  font-family: var(--FuenteSecundaria);
  display: inline-block;
  text-decoration: none;
  width: 100%;
}

.btnOfertasTabla {
  padding: 10px;
  width: 80%;
  background: var(--ColorBoton);
  border-radius: 10px;
  font-size: 13px;
  color: #fff !important;
  border: 0;
  text-decoration: none;
}

.button:hover {
  background-color: var(--ColorBoton); /* Green */
  color: white;
}

.numeros .active {
  width: -moz-max-content;
  width: max-content;
  border-radius: 20px;
  background: var(--ColorBoton);
  color: #fff;
}

.number-box span.number {
  color: var(--ColorBoton);
}

div#calculoDias .cont_loop h3.titt {
  text-align: center !important;
  font-family: var(--FuenteSecundaria), sans-serif;
  font-size: 1em;
  margin: 0;
  color: var(--ColorBoton);
}

.btn_bco:hover {
  background-color: var(--ColorBoton) !important;
  color: #fff !important;
  cursor: pointer;
  transition: ease-in 0.2s;
}

.iconoUsuarioLoggueado {
  color: var(--ColorBoton);
  margin-right: 0px;
  margin-left: 15px;
}

/* Media Querys que serán alterados por JS */
@media screen and (max-width: 600px) {
  button.btn.btn_morado,
  button.btn.btn_bco,
  .btn_morado,
  a.btn{
    max-width: 100%;
    margin: 1em 0 !important;
  }
  button.estiloBotonVivienda
  {
    max-width: 100%;
    margin: 1em 0 !important;
  }
}
@media screen and (max-height: 768px) {
  .btn_morado {
    background: var(--ColorBoton) !important;
    border-radius: 20px;
    padding: 15pxpx !important;
    font-size: 13px;
    color: #fff !important;
    /*border: 1px solid var(--ColorTexto);*/
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
  }
  .btn_morado_personalizado {
    background: var(--textoCalculadora) !important;
    /* border-radius: 20px; */
    padding: 15pxpx !important;
    font-size: 13px;
    color: #fff !important;
    /*border: 1px solid var(--ColorTexto);*/
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
  }
  .btn_personalizado {
    background: var(--textoCalculadora) !important;
    border-radius: 10px;
    padding: 15px 20px !important;
    font-size: 13px;
    color: #fff !important;
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type=range]::-webkit-slider-runnable-track {
    height: 16px;
    color: var(--ColorBoton);
    margin-top: 0px;
  }
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
            appearance: none;
    width: 10px;
    height: 20px;
    border-radius: 100%;
    background: var(--ColorTexto);
    cursor: pointer;
    box-shadow: -1300px 0px 0 1300px var(--ColorBoton);
  }
}
/* Clases que estan fuera de la categoria de modificación de JS */
.isotipo img {
  width: 100%;
}

.isotipo_remax img {
  width: 110px;
  padding: 5px;
  /*background-color: #fafafa;*/
}

.ocultar {
  display: none !important;
}

.menu_lateral {
  background: #f5f5f5;
  border-radius: 20px;
  max-width: 335px;
  width: 30%;
  height: 80vh;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 3%;
  box-sizing: border-box;
  margin-top: 100px;
}

.menu_lateral .copyright,
.menu_lateral_pop .copyright {
  flex-direction: column;
  display: flex;
  width: 100%;
  color: var(--ColorTexto);
  margin-top: 18%;
}

.menu_lateral .copyright a,
.menu_lateral_pop .copyright a {
  margin-bottom: 1em;
  color: var(--ColorTexto);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
  margin-top: 0;
}

.menu_lateral .copyright p,
.menu_lateral_pop .copyright p {
  font-size: 80%;
}

footer.cont_responsive .copyright,
footer.cont_responsive .copyright a,
footer.cont_responsive .copyright p {
  display: block;
  color: #fff;
  font-size: 80%;
}

.copyright {
  font-size: 79%;
  color: #d9d9d9;
  display: flex;
  justify-content: space-between;
}

h1.titt {
  margin: 0;
  font-size: 2.6em;
  color: var(--ColorTexto);
}

/** Footer **/
footer {
  background: var(--ColorTexto);
  padding: 2em 0 0;
}

.morado {
  color: var(--ColorBoton);
}

.cont_left {
  font-size: var(--FuentePrincipal) !important;
}

#msjClientesRef b,
#msjCreditosOtorgados b {
  font-family: var(--FuentePrincipal);
}

/** Modal de Cargando **/
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  opacity: 1;
}

.loading-text {
  font-family: var(--FuentePrincipal);
  margin-top: 20px;
  font-size: 24px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/** Calculadora **/
.resultados.bg_morado2 {
  background: var(--fondoCalculadora);
  border-radius: 30px;
}

.img-logo {
  width: 80%;
}

.titulo-sentimos {
  font-family: var(--FuentePrincipal);
  font-size: 3em;
  line-height: 1;
  margin-top: 0;
  font-weight: 400;
  color: #fff;
}

/** Redes Sociales de Header y Footer  **/
.redes-remax {
  display: none;
}

.redes-remax-show {
  display: block;
}

.redes-Consultores {
  display: none;
}

.redes-Consultores-show {
  display: block;
}

.redes-saturn {
  display: none;
}

.redes-saturn-show {
  display: block;
}

.redes-archandel {
  display: none;
}

.redes-archandel-show {
  display: block;
}

.redes-opera {
  display: none;
}

.redes-opera-show {
  display: block;
}

/*Footer*/


#redes-footerSaturn {
  display: none;
}

#redes-footerSaturn-show {
  display: block;
}

#redes-footerFinmax {
  display: none;
}

#redes-footerFinmax-show {
  display: block;
}

#redes-footerConsultores {
  display: none;
}

#redes-footerConsultores-show {
  display: block;
}

#redes-footerArchandel {
  display: none;
}

#redes-footerArchandel-show {
  display: block;
}

#redes-footerOpera {
  display: none;
}

#redes-footerOpera-show {
  display: block;
}

/****NUEVOS 2024***/
.bancoSeleccionadoTarjeta {
  border: 3px solid var(--ColorBoton);
}

.botones {
  align-items: center;
}

.grupo-botones a {
  margin: 20px;
}
.grupo-botones a:hover {
  color: var(--ColorBoton);
  font-weight: bold;
}

/**  Los pop de la librería de Swal **/
.swal2-popup {
  font-family: var(--FuenteSecundaria) !important;
}

.swal2-styled.swal2-cancel {
  background-color: var(--botonSwal) !important;
}

.swal2-styled.swal2-confirm {
  background-color: var(--ColorBoton) !important;
}

.swal2-title {
  color: black !important;
}

.swal2-loader {
  border-color: var(--ColorBoton) rgba(0, 0, 0, 0) var(--ColorBoton) rgba(0, 0, 0, 0) !important;
}

.oxygen-aos-enabled {
  overflow-x: hidden;
}

.ct-inner-content {
  width: 100%;
}

#section-2-16 > .ct-section-inner-wrap {
  max-width: 100%;
}

#section-2-75 > .ct-section-inner-wrap {
  padding-top: 120px;
  padding-right: 30px;
  padding-bottom: 0;
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#section-7-75 > .ct-section-inner-wrap {
  padding-top: 0;
  padding-right: 20px;
  padding-bottom: 0;
  padding-left: 20px;
}

#section-48-75 > .ct-section-inner-wrap {
  padding-top: 50px;
  padding-right: 0;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 991px) {
  #div_block-86-75 {
    width: 100%;
  }
  #div_block-8-75 {
    text-align: left;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }
  #section-2-75 > .ct-section-inner-wrap {
    display: flex;
    flex-direction: column;
  }
  #div_block-44-75 {
    flex-direction: column;
    display: flex;
  }
  #image-96-75 {
    height: 300px;
    width: 100%;
  }
  #div_block-3-75 {
    width: 100%;
    margin-bottom: 40px;
    flex-direction: column-reverse;
    display: flex;
  }
}
@media (max-width: 767px) {
  #div_block-86-75 {
    width: 100%;
  }
  #div_block-8-75 {
    padding-left: 40px;
    padding-right: 40px;
  }
  #div_block-85-75 {
    width: 100%;
  }
  #section-13-75 > .ct-section-inner-wrap {
    padding-right: 30px;
    padding-left: 30px;
  }
  #headline-89-75 {
    font-size: 2rem;
  }
  #section-7-75 > .ct-section-inner-wrap {
    padding-right: 30px;
    padding-left: 30px;
  }
  #div_block-3-75 {
    gap: 20px;
  }
  #section-48-75 > .ct-section-inner-wrap {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (max-width: 479px) {
  #section-2-75 > .ct-section-inner-wrap {
    padding-top: 100px;
  }
  #div_block-86-75 {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
}
.ct-section {
  width: 100%;
  background-size: cover;
  background-repeat: repeat;
}

.ct-section > .ct-section-inner-wrap {
  display: flex;
  flex-direction: column;
  align-items: center !important;
}

.ct-section-inner-wrap,
.oxy-header-container {
  max-width: 1120px;
}

.ct-section-inner-wrap {
  padding-top: 75px;
  padding-right: 20px;
  padding-bottom: 75px;
  padding-left: 20px;
}

.navbar-landing .ct-section-inner-wrap {
  padding-top: 15px;
  padding-right: 50px;
  padding-bottom: 15px;
  padding-left: 50px;
}

.navbar-landing:not(.ct-section):not(.oxy-easy-posts),
.navbar-landing.oxy-easy-posts .oxy-posts,
.navbar-landing.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.img-fluid {
  height: 130px;
  width: 370px;
}
@media (max-width: 980px) {
  div#div_block-44-75 {
    display: block;
  }
}
@media (max-width: 768px) {
  .img-fluid {
    width: 150px;
    height: 60px;
  }
}
@media (max-width: 767px) {
  /*.ct-section-inner-wrap {
    display: block !important;
  }*/
  div#div_block-3-75 {
    width: 100%;
  }
  div#div_block-86-75 {
    width: 72%;
  }
  section#section-13-75 {
    margin-top: 10%;
  }
  div#div_block-8-75 {
    display: block;
    text-align: center;
  }
  div#div_block-85-75 {
    width: 85%;
    display: block;
    margin: 0 auto;
  }
  h1#headline-5-75 {
    font-size: 32px;
  }
  .navbar-landing .ct-section-inner-wrap {
    padding-right: 30px;
    padding-left: 30px;
  }
}
.wrap-icon-social:not(.ct-section):not(.oxy-easy-posts),
.wrap-icon-social.oxy-easy-posts .oxy-posts,
.wrap-icon-social.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.footer .ct-section-inner-wrap {
  padding-top: 30px;
  padding-right: 50px;
  padding-bottom: 30px;
  padding-left: 50px;
}

.footer:not(.ct-section):not(.oxy-easy-posts),
.footer.oxy-easy-posts .oxy-posts,
.footer.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 767px) {
  .footer .ct-section-inner-wrap {
    padding-right: 30px;
    padding-left: 30px;
  }
  .footer:not(.ct-section):not(.oxy-easy-posts),
  .footer.oxy-easy-posts .oxy-posts,
  .footer.ct-section .ct-section-inner-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
  }
  .btn-simulador-seccion1:not(.ct-section):not(.oxy-easy-posts),
  .btn-simulador-seccion1.oxy-easy-posts .oxy-posts,
  .btn-simulador-seccion1.ct-section .ct-section-inner-wrap {
    display: none;
  }
}
.btn-simulador-seccion1:not(.ct-section):not(.oxy-easy-posts),
.btn-simulador-seccion1.oxy-easy-posts .oxy-posts,
.btn-simulador-seccion1.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.block-caracteristicas:not(.ct-section):not(.oxy-easy-posts),
.block-caracteristicas.oxy-easy-posts .oxy-posts,
.block-caracteristicas.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.toggle-faqs:not(.ct-section):not(.oxy-easy-posts),
.toggle-faqs.oxy-easy-posts .oxy-posts,
.toggle-faqs.ct-section .ct-section-inner-wrap {
  display: none;
}

#section-13-75 > .ct-section-inner-wrap {
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
}

#section-13-75 {
  background-color: #fafafa;
}

#section-7-75 > .ct-section-inner-wrap {
  padding-top: 0;
  padding-right: 20px;
  padding-bottom: 0;
  padding-left: 20px;
}

.headline-paso:not(.ct-section):not(.oxy-easy-posts),
.headline-paso.oxy-easy-posts .oxy-posts,
.headline-paso.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#div_block-3-75 {
  width: 80%;
  text-align: left;
  gap: 50px;
}

.ct-div-block {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
}

.ct-new-columns > .ct-div-block {
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}

#text_block-4-75 {
  color: var(--ColorBoton);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ct-div-block,
  .oxy-post-content,
  .ct-text-block,
  .ct-headline,
  .oxy-rich-text,
  .ct-link-text {
    max-width: 100%;
  }
}
.body-small-bold {
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#headline-5-75 {
  font-size: 2.75rem;
}

.ct-text-block {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.ct-text-block p {
  margin-bottom: 20px;
}

.ct-text-block h1,
.ct-text-block h2,
.ct-text-block h3,
.ct-text-block h4 {
  margin-top: 20px;
}

.ct-text-block .wp-block-image {
  width: 100%;
  margin: 30px 0 30px 0;
}

.ct-image {
  max-width: 100%;
}

#image-96-75 {
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 30px;
  width: 100%;
  height: 300px;
}

div.oxy-dynamic-list div.oxy-product-images.oxy-woo-element {
  align-self: stretch;
}

#headline-89-75 {
  color: #160f29;
  text-align: left;
}

#shortcode-90-75 {
  width: 100%;
}

#wpcf7-f33-o1 {
  font-family: inherit;
  width: 100%;
}

#wpcf7-f33-o1 .wpcf7-form br {
  display: none;
  visibility: hidden;
}

#wpcf7-f33-o1 .wpcf7-form p:nth-child(2) {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}

#wpcf7-f33-o1 .wrap-field {
  position: relative;
  margin-bottom: 20px;
}

#wpcf7-f33-o1 .wrap-field label {
  background-color: #fafafa;
  color: #160f29;
  font-size: 10px;
  text-transform: uppercase;
  padding: 0 10px;
  position: absolute;
  top: -7px;
  margin-left: 15px;
  z-index: 10;
}

#wpcf7-f33-o1 .wrap-field input[type=email],
#wpcf7-f33-o1 .wrap-field input[type=text],
#wpcf7-f33-o1 .wrap-field input[type=tel],
#wpcf7-f33-o1 .wrap-field textarea {
  background-color: transparent;
  width: 100%;
  border: solid 1px #d9d9d9;
  color: #160f29;
  font-size: 16px;
  padding: 15px 15px 10px 15px;
  border-radius: 15px;
  outline: none;
}

#wpcf7-f33-o1 .wrap-field input[type=email]:focus,
#wpcf7-f33-o1 .wrap-field input[type=text]:focus,
#wpcf7-f33-o1 .wrap-field input[type=tel]:focus,
#wpcf7-f33-o1 .wrap-field textarea:focus {
  border: solid 1px #9c36b2;
}

#wpcf7-f33-o1 .wrap-field textarea {
  height: 100px;
}

#wpcf7-f33-o1 input[type=submit] {
  background-color: #160f29;
  color: #fafafa;
  border: none;
  padding: 15px 20px;
  border-radius: 15px;
  transition: background 0.5s ease;
  cursor: pointer;
}

#wpcf7-f33-o1 input[type=submit]:hover {
  background-color: #9c36b2;
}

#wpcf7-f152-o2 {
  font-family: inherit;
  width: 100%;
}

#wpcf7-f152-o2 .wpcf7-form br {
  display: none;
  visibility: hidden;
}

#wpcf7-f33-o1 .wpcf7-form p:nth-child(2) {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}

#wpcf7-f152-o2 .wrap-field {
  position: relative;
  margin-bottom: 20px;
}

#wpcf7-f152-o2 .wrap-field label {
  background-color: #fafafa;
  color: #160f29;
  font-size: 10px;
  text-transform: uppercase;
  padding: 0 10px;
  position: absolute;
  top: -7px;
  margin-left: 15px;
  z-index: 10;
}

#wpcf7-f152-o2 .wrap-field input[type=email],
#wpcf7-f152-o2 .wrap-field input[type=text],
#wpcf7-f152-o2 .wrap-field input[type=tel],
#wpcf7-f152-o2 .wrap-field textarea {
  background-color: transparent;
  width: 100%;
  border: solid 1px #d9d9d9;
  color: #160f29;
  font-size: 16px;
  padding: 15px 15px 10px 15px;
  border-radius: 15px;
  outline: none;
}

#wpcf7-f152-o2 .wrap-field input[type=email]:focus,
#wpcf7-f152-o2 .wrap-field input[type=text]:focus,
#wpcf7-f152-o2 .wrap-field input[type=tel]:focus,
#wpcf7-f152-o2 .wrap-field textarea:focus {
  border: solid 1px #9c36b2;
}

#wpcf7-f152-o2 .wrap-field textarea {
  height: 100px;
}

#wpcf7-f152-o2 input[type=submit] {
  background-color: #160f29;
  color: #fafafa;
  border: none;
  padding: 15px 20px;
  border-radius: 15px;
  transition: background 0.5s ease;
  cursor: pointer;
}

#wpcf7-f152-o2 input[type=submit]:hover {
  background-color: #9c36b2;
}

#div_block-44-75 {
  align-items: stretch;
  /*grid-template-columns: repeat(3, minmax(200px, 1fr));*/
  grid-template-columns: minmax(200px, 1fr) minmax(250px, 2fr) minmax(200px, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  text-align: left;
  display: grid;
}

#text_block-97-75 {
  color: var(--ColorBoton);
  margin-top: 5px;
}

.ct-link-button {
  border-radius: 3px;
}

#div_block-85-75 {
  flex-direction: column;
  display: flex;
  text-align: left;
  gap: 20px;
  width: 45%;
}

.ct-link-text {
  text-decoration: none;
  display: inline-block;
}

.ct-link {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  text-decoration: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ct-link-button {
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

.ct-link-button {
  background-color: #1e73be;
  border: 1px solid #1e73be;
  color: #ffffff;
  padding: 10px 16px;
}

.btn-ppal-neutro:after {
  position: absolute;
  z-index: 1;
  height: 20px;
  width: 20px;
  border-radius: 100px;
  background-color: #574980;
  content: "";
}

.btn-ppal-neutro {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: var(--ColorBoton);
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.btn-ppal:after,
.btn-neutro:after,
.btn-ppal-morado:after,
.btn-ppal-neutro:after {
  transition: transform 0.75s ease, opacity 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);
  opacity: 0;
}

.btn-ppal:hover:after,
.btn-neutro:hover:after,
.btn-ppal-morado:hover:after,
.btn-ppal-neutro:hover:after {
  transform: scale(30);
  opacity: 1;
}

#text_block-84-75 {
  color: #fafafa;
  z-index: 10;
}

#div_block-8-75 {
  color: white;
  margin-bottom: 50px;
  width: 70%;
  flex-direction: row;
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  background-color: #160f29;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 60px;
}

#div_block-86-75 {
  padding: 40px;
  gap: 30px;
  width: 50%;
  background-color: rgb(250, 250, 250);
  height: auto;
  flex-direction: column;
  display: flex;
  justify-content: center;
  border-radius: 30px;
  box-shadow: rgba(22, 15, 41, 0.5) 10px 10px 32px -12px;
  min-width: min-content;
}/*# sourceMappingURL=style.css.map */

.margin_top_20{
  margin-top: 20px !important;
}

.cont-div-img{
  display: flex;

  img{
    object-fit: cover;
    height: 100%;
    border-radius: 30px;
  }
}
