@font-face {
  font-family: "Gotham";
  src: url("../styles/fonts/GothamBook.woff2") format("woff2"),
    url("../styles/fonts/GothamBook.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Geomanist";
  src: url("../styles/fonts/GeomanistRegular.woff2") format("woff2"),
       url("../styles/fonts/GeomanistMedium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Geometos";
  src: url("../styles/fonts/GeometosNeue.woff2") format("woff2"),
       url("../styles/fonts/GeometosNeueBold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Aptos";
  src: url("../styles/fonts/Aptos.woff2") format("woff2"),
       url("../styles/fonts/Aptos-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../styles/fonts/Montserrat-Regular.woff2") format("woff2"),
       url("../styles/fonts/Montserrat-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

root {
  --ColorBoton: #9c36b2;
  --ColorTexto: #160f29;
  --BotonGris: #45434d;
  --botonSwal: #160f29;
  --fondoCalculadora: #160f29;
  --textoCalculadora: #9c36b2;
  --FuentePrincipal: "DM Serif Display", serif;
  --FuenteSecundaria: "Inter", sans-serif;
  --ColorHoverBtn: #fff; 
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--FuentePrincipal);
}
h1 {
  font-size: 3em;
  line-height: 1;
  margin-top: 0;
  color: var(--ColorTexto);
  font-weight: 400 !important;
}
p {
  font-family: var(--FuenteSecundaria), sans-serif;
  color: var(--ColorTexto);
}
p.col6 {
  padding: 0 !important;
}
small {
  font-family: var(--FuenteSecundaria);
  line-height: 20px;
}
.leyeda {
  font-family: var(--FuenteSecundaria);
  line-height: 20px;
}

small a {
  font-weight: 700;
}
.fa {
  margin-right: 14px;
}
.fa-classic,
.fa-light,
.fa-regular,
.fa-solid,
.fa-thin,
.fal,
.far,
.fas,
.fat {
  font-family: "Font Awesome 6 Pro";
}
.fa-grid-2:before {
  content: "\e196";
}
.fa-memo:before {
  content: "\e1d8";
}
.fa-check-circle:before,
.fa-circle-check:before {
  content: "\f058";
}
.fa-circle-exclamation:before,
.fa-exclamation-circle:before {
  content: "\f06a";
}
i.fa.fa-circle-check {
  color: #d9d9d9;
}

button.btn.btn_morado {
  width: 50%;
  max-width: 200px;
  border: 0;
}

.Resum.cont-Cotizaciones .bg_morado2 a.btn_morado {
  width: auto !important;
}

.dosColumns.cont_flex .btn_morado {
  display: block;
  width: 75%;
  text-align: center;
}

.btn_morado .bg_morado2 {
  width: 100%;
  display: block;
}

.bg_morado2 a.btn_morado {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  margin-top: 1em;
}

.btn_morado_personalizado {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  margin-top: 1em;
}

button.btn.btn_morado {
  width: 50%;
  max-width: 200px;
  border: 0;
}

.btn,
button.btn.btn_bco,
button.btn.btn_morado {
  box-sizing: border-box;
  // margin: 1em 0;
  // width: max-content;
}

input.btn.btn_morado[type="file"] {
  margin: 0;
}

input.btn.btn_morado[type="file"] {
  background: no-repeat;
  appearance: none !important;
  border: 1px dashed #333;
}

input.btn.btn_morado[type="file"] {
  position: absolute;
  height: 210px;
  left: 0;
  top: 0;
  text-align: center;
  opacity: 0;
}

/*--------------------------------- */
/* Clases que estarán modificadas mediante JS */
/*--------------------------------- */

.btn_morado {
  background: var(--ColorBoton) !important;
  border-radius: 10px;
  padding: 15px 20px !important;
  font-size: 13px;
  color: #fff !important;
  display: inline-block;
  text-decoration: none;
  margin: 0 10px;
}

.btn_personalizado a {
  border-radius: 10px;
  padding: 15px 20px !important;
  font-size: 13px;
  color: var(--ColorTexto);
  border: 1px solid var(--ColorTexto);
  font-family: "Inter";
  cursor: pointer;
  text-align: center;
}

.btn_personalizado {
  width: 15% !important;
  background: var(--textoCalculadora) !important;
  border-radius: 10px;
  padding: 15px 20px !important;
  font-size: 13px;
  color: #fff !important;
  display: inline-block;
  text-decoration: none;
  margin: 0 10px;
}

.btn_morado_personalizado {
  background: var(--textoCalculadora) !important;
  border-radius: 10px;
  padding: 15px 20px !important;
  font-size: 13px;
  color: #fff !important;
  display: inline-block;
  text-decoration: none;
  margin: 10px auto;
}

.btn_regresar {
  width: 15% !important;
  border-radius: 10px;
  font-size: 13px;
  color: var(--ColorBoton) !important;
  display: inline-block;
  margin: 0 10px;
}

a.btn.btn_gris:hover {
  background: var(--ColorBoton);
  color: #fff;
}

.btn_bco {
  background: #fff;
  border-radius: 10px;
  padding: 15px 20px !important;
  border: 1px solid var(--ColorBoton);
  color: var(--ColorBoton) !important;
}

a.link {
  color: var(--ColorBoton);
  font-family: var(--FuenteSecundaria);
  font-size: 12px;
  margin: 1em 0;
}

.link {
  color: var(--ColorBoton);
  font-family: var(--FuenteSecundaria);
  text-decoration: none;
}

a.back {
  color: var(--ColorBoton);
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px !important;
  display: block;
}

form label {
  display: block;
  font-family: var(--FuenteSecundaria);
  margin-bottom: 10px;
  color: var(--ColorBoton);
  padding-top: 15px;
}

ul.check a {
  color: var(--ColorTexto);
}

/** Botones **/
.bg_gris2 {
  height: 100%;
  box-sizing: border-box;
  padding: 32px;
  background: var(--BotonGris);
  border-radius: 30px;
  color: #fff;
  box-shadow: 10px 10px 30px 0px var(--ColorTexto) 40;
}

.button_YN {
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  border-radius: 10px;
  padding: 15px 20px !important;
  font-size: 13px;
  border: 1px solid var(--ColorTexto);
  font-family: "Inter";
  display: inline-block;
  text-decoration: none;
}

a.btn {
  border-radius: 10px;
  padding: 15px 20px !important;
  font-size: 13px;
  color: var(--ColorTexto);
  border: 1px solid var(--ColorTexto);
  font-family: "Inter";
  cursor: pointer;
  text-align: center;
}

.btn_nuevo,
.bg_morado2 {
  background: var(--fondoCalculadora);
  border-radius: 10px;
  color: #fff !important;
}

.cont_flex .col6 {
  box-sizing: border-box;
  // width: 50%;
}

/** Menú Lateral **/

.menu_lateral ul li a.active {
  background: var(--ColorBoton);
  color: #fff;
}

.menu_lateral .copyright p a,
.menu_lateral_pop .copyright p a {
  color: var(--ColorBoton);
  font-size: 100%;
}

.menu_lateral ul li a {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  box-sizing: border-box;
  margin-bottom: 15px;
  background: #fafafa;
  border-radius: 10px;
  color: var(--ColorTexto);
}

.menu_lateral_pop ul li a {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  box-sizing: border-box;
  margin-bottom: 50px;
  background: #f0f0f0;
  border-radius: 10px;
  color: var(--ColorTexto);
  font-size: 18px;
}

/** Aprobado **/
.col6.cont_img .bg_morado2 {
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  margin-bottom: 1em;
  padding: 50px;
  box-shadow: 10px 10px 30px 0px var(--ColorTexto) 40;
}

i.fa.fa-circle-exclamation {
  color: #fff;
  font-size: 1.4em;
}

/** Documentos Enviados **/

.bg_verde {
  padding: 30px !important;
  background-color: #8bbf21 !important;
  border-radius: 30px;
  height: 100%;
  color: var(--ColorTexto);
}

.col6.bg_verde a {
  background: var(--ColorTexto);
  color: #fff;
  margin: 1em 0;
  display: inline-block;
  text-decoration: none;
}

.cont_loop.sinFondo h3 {
  color: var(--ColorTexto);
  font-family: "inter";
  font-size: 20px;
  font-weight: 600;
}

.cont_loop.bg_descarga h3 {
  font-family: inter;
  color: var(--ColorTexto);
  font-size: 20px;
  font-weight: 600;
}

.cont_loop.bg_gris h3 {
  font-family: inter;
  color: var(--ColorTexto);
  font-weight: 600;
  font-size: 20px;
}

.cont_loop.sinFondo label {
  position: absolute;
  display: flex;
  align-items: center;
  font-family: "inter";
  padding: 10px;
  color: var(--ColorTexto);
  box-sizing: border-box;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D9D9D9FF' stroke-width='2' stroke-dasharray='5%2c 10' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
  width: 70%;
  border-radius: 15px;
  overflow: hidden;
  word-wrap: break-word;
  white-space: pre-wrap;
  max-height: 120px;
  text-indent: 10px;
}

.cont_loop h3 {
  color: var(--ColorBoton);
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 10px;
}

.col6.simulador label {
  color: var(--textoCalculadora);
  display: block;
}

/** Información **/
#informacion h3 {
  font-size: 20px;
  color: var(--ColorTexto);
  font-family: inter;
  font-weight: 600;
}

span.select {
  height: 5px;
  background: linear-gradient(45deg, var(--ColorBoton) 50%, #d9d9d9 50%);
  width: 100%;
  display: block;
  margin: auto;
  border-radius: 10px;
}

span.select:before {
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  background: var(--ColorBoton);
  margin: -8px auto;
  border-radius: 100%;
}

span.lines {
  width: 100%;
  height: 2px;
  background: var(--ColorBoton);
  display: block;
  position: relative;
}

span.lines:before,
span.lines:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: var(--ColorBoton);
  margin: 8px 0;
  position: absolute;
}

.botonAplicarGrande {
  width: 80%;
  background: var(--ColorBoton);
  border-radius: 10px;
  padding: 15px 20px !important;
  font-size: 13px;
  color: #fff !important;
  border: 1px solid var(--ColorTexto);
  font-family: var(--FuenteSecundaria);
  display: inline-block;
  text-decoration: none;
}

.btn_plazo_selected {
  background-color: var(--ColorBoton);
  font-weight: bold;
}

input[type="range"]::-moz-range-progress {
  background-color: var(--ColorBoton);
}

input[type="range"]::-ms-fill-lower {
  background-color: var(--ColorBoton);
}

.precio_plazo button:hover,
.precio_plazo button.btn_plazo_selected {
  color: #fff;
  background: var(--ColorBoton);
}

.btn_morado_modal {
  background: var(--ColorBoton);
  border-radius: 10px;
  padding: 15px 20px !important;
  font-size: 15px;
  color: #fff !important;
  border: 1px solid var(--ColorBoton);
  font-family: var(--FuenteSecundaria);
  display: inline-block;
  text-decoration: none;
  width: 100%;
}

.btnOfertasTabla {
  padding: 10px;
  width: 80%;
  background: var(--ColorBoton);
  border-radius: 10px;
  font-size: 13px;
  color: #fff !important;
  border: 0;
  text-decoration: none;
}

.button:hover {
  background-color: var(--ColorBoton); /* Green */
  color: white;
}

.numeros .active {
  width: max-content;
  border-radius: 20px;
  background: var(--ColorBoton);
  color: #fff;
}

.number-box span.number {
  color: var(--ColorBoton);
}

div#calculoDias .cont_loop h3.titt {
  text-align: center !important;
  font-family: var(--FuenteSecundaria), sans-serif;
  font-size: 1em;
  margin: 0;
  color: var(--ColorBoton);
}

.btn_bco:hover {
  background-color: var(--ColorBoton) !important;
  color: var(--ColorHoverBtn) !important;
  cursor: pointer;
  transition: ease-in 0.2s;
}

.iconoUsuarioLoggueado {
  color: var(--ColorBoton);
  margin-right: 0px;
  margin-left: 15px;
}

/* Media Querys que serán alterados por JS */

@media screen and (max-width: 600px) {
  button.btn.btn_morado {
    max-width: 100%;
  }
}

// @media (min-width: 768px) {
//   .cont_flexible {
//     grid-template-columns: 1fr 1fr;
//   }
// }

@media screen and (max-height: 768px) {
  .btn_morado {
    background: var(--ColorBoton) !important;
    border-radius: 20px;
    padding: 15pxpx !important;
    font-size: 13px;
    color: #fff !important;
    /*border: 1px solid var(--ColorTexto);*/
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
  }

  .btn_morado_personalizado {
    background: var(--textoCalculadora) !important;
    border-radius: 10px;
    padding: 15pxpx !important;
    font-size: 13px;
    color: #fff !important;
    /*border: 1px solid var(--ColorTexto);*/
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
  }

  .btn_personalizado {
    background: var(--textoCalculadora) !important;
    border-radius: 10px;
    padding: 15px 20px !important;
    font-size: 13px;
    color: #fff !important;
    display: inline-block;
    text-decoration: none;
    margin: 0 10px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"]::-webkit-slider-runnable-track {
    height: 16px;
    color: var(--ColorBoton);
    margin-top: 0px;
  }

  input[type="range"]::-webkit-slider-thumb {
    appearance: none;
    width: 10px;
    height: 20px;
    border-radius: 100%;
    background: var(--ColorTexto);
    cursor: pointer;
    box-shadow: -1300px 0px 0 1300px var(--ColorBoton);
  }
}

/* Clases que estan fuera de la categoria de modificación de JS */

.isotipo img {
  // width: 100%;
  width: 110px;
}

.isotipo_remax img {
  width: 110px;
  max-height: 55px;
  object-fit: contain;
}

.ocultar {
  display: none !important;
}

.menu_lateral {
  background: #f5f5f5;
  border-radius: 20px;
  max-width: 335px;
  width: 30%;
  height: 80vh;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 3%;
  box-sizing: border-box;
  margin-top: 100px;
}

.menu_lateral .copyright,
.menu_lateral_pop .copyright {
  flex-direction: column;
  display: flex;
  width: 100%;
  color: var(--ColorTexto);
  margin-top: 18%;
}
.menu_lateral .copyright a,
.menu_lateral_pop .copyright a {
  margin-bottom: 1em;
  color: var(--ColorTexto);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: fit-content;
  text-decoration: none;
  margin-top: 0;
}

.menu_lateral .copyright p,
.menu_lateral_pop .copyright p {
  font-size: 80%;
}

footer.cont_responsive .copyright,
footer.cont_responsive .copyright a,
footer.cont_responsive .copyright p {
  display: block;
  color: #fff;
  font-size: 80%;
}

.copyright {
  font-size: 79%;
  color: #d9d9d9;
  display: flex;
  justify-content: space-between;
}

h1.titt {
  margin: 0;
  font-size: 2.6em;
  color: var(--ColorTexto);
}

/** Footer **/

footer {
  background: var(--ColorTexto);
  padding: 2em 0 0;
}

.morado {
  color: var(--ColorBoton);
}

.cont_left {
  font-size: var(--FuentePrincipal) !important;
}

#msjClientesRef,
#msjCreditosOtorgados {
  b {
    font-family: var(--FuentePrincipal);
  }
}

/** Modal de Cargando **/
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  opacity: 1;
}

// .loading-spinner {
//   border: 12px solid #f3f3f3;
//   border-top: 12px solid #3498db;
//   border-radius: 50%;
//   width: 80px;
//   height: 80px;
//   animation: spin 2s linear infinite, fadeOut 1s ease-out 10s forwards;
// }

.loading-text {
  font-family: var(--FuentePrincipal);
  margin-top: 20px;
  font-size: 24px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/** Calculadora **/
.resultados.bg_morado2 {
  background: var(--fondoCalculadora);
  border-radius: 30px;
}

.img-logo {
  width: 80%;
}

.titulo-sentimos {
  font-family: var(--FuentePrincipal);
  font-size: 3em;
  line-height: 1;
  margin-top: 0;
  font-weight: 400;
  color: #fff;
}

/** Redes Sociales de Header y Footer  **/

.redes-remax {
  display: none;
}

.redes-remax-show {
  display: block;
}

.redes-Consultores {
  display: none;
}

.redes-Consultores-show {
  display: block;
}

.redes-saturn {
  display: none;
}

.redes-saturn-show {
  display: block;
}

.redes-archandel {
  display: none;
}

.redes-archandel-show {
  display: block;
}

.redes-opera {
  display: none;
}

.redes-opera-show {
  display: block;
}

// Redes Footer

#redes-footerSaturn {
  display: none;
}

#redes-footerSaturn-show {
  display: block;
}

#redes-footerFinmax {
  display: none;
}

#redes-footerFinmax-show {
  display: block;
}

#redes-footerConsultores {
  display: none;
}

#redes-footerConsultores-show {
  display: block;
}

#redes-footerArchandel {
  display: none;
}

#redes-footerArchandel-show {
  display: block;
}

#redes-footerOpera {
  display: none;
}

#redes-footerOpera-show {
  display: block;
}

/****NUEVOS 2024***/

.bancoSeleccionadoTarjeta {
  cursor: pointer;
  min-width: 250px;
  border: 3px solid var(--ColorBoton);
}

.botones {
  align-items: center;
}

.grupo-botones {
  a {
    margin: 20px;
  }

  a:hover {
    color: var(--ColorBoton);
    font-weight: bold;
  }
}

/**  Los pop de la librería de Swal **/

.swal2-popup {
  font-family: var(--FuenteSecundaria) !important;
}

.swal2-styled.swal2-cancel {
  background-color: var(--botonSwal) !important;
}
.swal2-styled.swal2-confirm {
  background-color: var(--ColorBoton) !important;
}

.swal2-title {
  color: black !important;
}

.swal2-loader {
  border-color: var(--ColorBoton) rgba(0, 0, 0, 0) var(--ColorBoton)
    rgba(0, 0, 0, 0) !important;
}
